import { useRef } from 'react';
import AdminCreateReportMonikers from './AdminCreateReportMonikers';

const AdminCreateReportVictims = ({
  formMethods,
  errors,
  setErrors,
}: {
  formMethods: any;
  errors: any;
  setErrors: any;
}) => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  return (
    <div className="flex flex-row h-full pb-4 ">
      {/* Main content (forms) */}

      <div className="w-full h-full mx-4 ">
        <AdminCreateReportMonikers
          formMethods={formMethods}
          selectedCategory={'Victims'}
          sectionRefs={sectionRefs}
          showCategory={false}
        />
      </div>
    </div>
  );
};

export default AdminCreateReportVictims;
