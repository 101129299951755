import DynamicTable from '@components/common/DynamicTable';
import { filterEmptyRows } from '@utils/utils';
import { getFormItemsDisplay } from '../CreateReportPage/AdminCreateReportFormConfig';

const VictimsDisplay = ({ formMethods, formValues }: { formMethods: any; formValues: any }) => {
  let victimRecords = filterEmptyRows(formValues?.scamEnablers?.Victims);

  return (
    <>
      {victimRecords?.length > 0 && (
        <div className="flex flex-col gap-2">
          <h3 className="text-black-400 font-bold mb-4">Victim's Information</h3>

          {victimRecords.map((victim: any, index: number) => {
            return (
              <div className="bg-white p-4 rounded-lg mb-2">
                <DynamicTable
                  tableId={`scamEnablers[Victims][${index}].rows`}
                  formFields={getFormItemsDisplay('', 'Victims')}
                  isEditable={false}
                  formMethods={formMethods}
                  data={victim.rows}
                  defaultRow={{}}
                  showCounter={false}
                  isSimpleView={true}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

// return (
//   <div className="flex flex-col gap-1 h-[45vh] 2xl:h-[52vh] overflow-y-auto overflow-x-hidden">
//     {getScamEnablersComponent()}
//   </div>
// );
// };

export default VictimsDisplay;
