import { Table, Pagination, ConfigProvider } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import cloneDeep from 'lodash.clonedeep';
import { getBankColumns } from '../getColumns';
import useTableParams from '@components/common/useTableParams';
import { useEffect, useRef, useState } from 'react';
import FilterAssets from '@components/common/FilterAssets';
import FilterModal from '@components/common/FilterModal';
import { CommonButton } from '@components/common/CommonButton';
import { useForm } from 'react-hook-form';
import { bankFilterGeneralItems } from './BankFormConfig';
import AccessControl from '@components/common/AccessControl';
import { DownloadButton, AssignBankButton, UpdateOutcomeButton } from '../Buttons';
import { useGetAllFinancialInstituteEntriesQuery } from '@api/financialInstituteSlice';
import { AssociatedReportsSection } from './AssociatedReportsSection';

export function BankTable({ platforms }: { platforms?: string }) {
  const [searchTerm, setSearchTerm] = useState('');

  const defaultFilterValues = {
    outcome: [],
    platform: [],
    created_date_from: '',
    created_date_to: '',
    dutyOfficer: '',
  };

  const initialParams = {
    sort: {
      sortId: 'createdAt',
      sortOrder: 'desc' as const, // Update the sortOrder property to have a value of 'asc' as a string literal
    },
    pagination: {
      current: 1,
      pageSize: 50,
    },
    filters: defaultFilterValues,
  };

  const formMethods = useForm({
    defaultValues: defaultFilterValues,
    mode: 'onChange',
  });

  const { tableParams, setTableParams, handlePageChange, handleTableChange } =
    useTableParams(initialParams);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [expandedRows, setExpandedRows] = useState<Array<string>>([]);

  const handleExpandRow = (id: string) => {
    if (!expandedRows.includes(id)) {
      setExpandedRows([...expandedRows, id]);
    } else {
      setExpandedRows(expandedRows.filter((expandedId) => expandedId != id));
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
    });
    setSearchTerm(e.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
    },
  };

  const handleFiltersChange = (filters: any) => {
    if (filters.platform) {
      filters.platform = filters.platform.join(',');
    }
    if (filters.dutyOfficer) {
      filters.dutyOfficer = filters?.dutyOfficer?.id ? filters.dutyOfficer.id : '';
    }
    if (filters.created_date_from) {
      filters.created_date_from = Date.parse(filters.created_date_from);
    }
    if (filters.created_date_to) {
      filters.created_date_to = Date.parse(filters.created_date_to);
    }

    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters,
    });
  };

  const { refetch, data, isSuccess, isError, isLoading, isFetching } =
    useGetAllFinancialInstituteEntriesQuery({
      params: {
        search: searchTerm,
        sort_by: tableParams.sort.sortId,
        order_by: tableParams.sort.sortOrder,
        page: tableParams.pagination.current,
        size: tableParams.pagination.pageSize,
        filters: tableParams.filters,
      },
    });

  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [data]);

  const columns = getBankColumns(refetch, handleExpandRow, expandedRows);

  return (
    <>
      <AccessControl type="page" allowedPermissions={['portfolios.banks.view']}>
        <div className="rounded-lg bg-white p-2 md:pt-0 overflow-y-auto">
          <div className="flex items-center justify-between gap-2 py-3">
            <div className="flex flex-row gap-4 items-center">
              <div className="w-64 ">
                <DebounceInput
                  className="w-64 h-12 p-4 bg-grey-300 rounded-[8px] border-none text-grey-500 text-sm"
                  minLength={2}
                  debounceTimeout={300}
                  placeholder="Search for bank account number..."
                  onChange={handleSearch}
                />
              </div>
              <CommonButton
                isDisabled={false}
                variant="secondary"
                title={`Filters`}
                onClick={() => setFilterOpen(true)}
              />
            </div>

            <div className="flex flex-row gap-2 mr-4">
              {/* <DownloadButton selectedScamEnablers={selectedRows} /> */}
              <AccessControl type="component" allowedPermissions={['portfolios.banks.assign']}>
                <AssignBankButton
                  selectedBanks={selectedRows}
                  clearSelectedRows={() => {
                    setSelectedRows([]);
                    refetch();
                  }}
                />
              </AccessControl>
              <AccessControl type="component" allowedPermissions={['portfolios.banks.update']}>
                <UpdateOutcomeButton
                  selectedBanks={selectedRows}
                  callback={() => {
                    setSelectedRows([]);
                    refetch();
                  }}
                  type="text"
                />
              </AccessControl>
            </div>
          </div>
          <FilterAssets
            defaultValues={defaultFilterValues}
            formMethods={formMethods}
            handleFiltersChange={handleFiltersChange}
          />
          <div className="max-h-[calc(100vh-300px)] overflow-auto" ref={tableRef}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: '#D0DEE0',
                  },
                },
              }}
            >
              <Table
                rowSelection={{ ...rowSelection }}
                dataSource={cloneDeep(data?.financialInstitutes)}
                columns={columns}
                rowKey="_id"
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading || isFetching}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record) => {
                    return <AssociatedReportsSection record={record} />;
                  },
                }}
                sticky
              />
            </ConfigProvider>
          </div>

          <Pagination
            className="pt-2 flex justify-end"
            defaultCurrent={1}
            current={tableParams.pagination.current}
            total={data?.totalNumberOfRecords}
            onChange={handlePageChange}
            showSizeChanger={true}
            defaultPageSize={50}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
          <FilterModal
            filterOpen={filterOpen}
            formItems={bankFilterGeneralItems}
            formMethods={formMethods}
            onClearFilters={() => formMethods.reset(defaultFilterValues)}
            onFilterClose={() => setFilterOpen(false)}
            onSubmitFilter={(filters: any) => {
              handleFiltersChange(filters);
            }}
          />
        </div>
      </AccessControl>
    </>
  );
}
