import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import { useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';
import { useCreateReportMutation } from '@api/reportsSlice';
import { useDispatch } from 'react-redux';
import { formatScamEnablers } from '../CreateReportPage/CreateReportComponent';
import AdminCreateReportScamEnablers from '../CreateReportPage/AdminCreateReportScamEnablers';
import {
  getScamEnablers,
  handleCreateReportButton,
  validateScamEnablers,
} from '../CreateReportPage/AdminCreateReportUtility';
import { useForm } from 'react-hook-form';
import { showAlert } from '@store/alertSlice';

export const formatEditedForm = (formData: any, report: any, user: any) => {
  let params: any = {};
  params.report = {
    reportNumber: report.reportNumber,
    createdBy: report.createdBy,
    classification: report.classification,
    spfEmail: user.email,
    policeStation: 'CAD / ASCOM',
    scamType: report.scamType,
  };
  params.scamEnablers = formatScamEnablers(formData.scamEnablers);
  // console.log('params', params);

  return params;
};

export default function AddScamEnablersModal({
  isOpen,
  setIsOpen,
  reportId,
  report,
  currentUser,
  callback,
}: {
  isOpen: boolean;
  setIsOpen: any;
  reportId: string;
  report: any;
  currentUser: any;
  callback: any;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createReport] = useCreateReportMutation();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>([]);

  const isShowModal = false;

  const formMethods = useForm({
    values: {
      reportNumber: report.reportNumber,
      classification: report.classification,
      spfEmail: currentUser.email,
      policeStation: 'CAD / ASCOM',
      scamType: report.scamType,
      scamEnablers: {
        FinancialInstitutes: [{ rows: [{}, {}] }],
        Paynow: [{ rows: [{}, {}] }],
        Monikers: [{}],
        Victims: [{ rows: [{}, {}] }],
        Telcos: [{ rows: [{}, {}] }],
      },
    },
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    let errors = validateScamEnablers(formMethods.getValues(), formMethods, true);

    if (
      errors?.bank?.length > 0 ||
      errors?.fintech?.length > 0 ||
      errors?.paynow?.length > 0 ||
      errors?.monikers?.length > 0
    ) {
      setErrors(errors);
      dispatch(
        showAlert({
          type: 'error',
          message: `Some fields contain errors. Please correct them before proceeding to the next section.`,
        }),
      );
      return;
    } else if (errors?.scamEnablers?.length > 0) {
      dispatch(
        showAlert({
          type: 'error',
          message: `Please enter at least one scam enabler.`,
        }),
      );
      return;
    }
    await handleCreateReportButton(
      formMethods,
      currentUser,
      dispatch,
      () => {
        setIsOpen(false);
        callback();
      },
      createReport,
      () => {},
      setIsSubmitting,
    );
  };

  const isButtonDisabled = () => {
    let formScamEnablers = getScamEnablers(formMethods);
    if (!formScamEnablers) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalLayout
        title={'Add Scam Enablers'}
        handleCloseModal={() => {
          setIsOpen(false);
        }}
        alignment={'center'}
        size={'large'}
        overflowClass="none"
      >
        <div className="w-[65vw]">
          <AdminCreateReportScamEnablers
            formMethods={formMethods}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <div className="flex gap-4 mr-0 ml-auto mt-4 ">
          <CommonButton
            variant={'text'}
            title={'Cancel'}
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <CommonButton
            variant={'primary'}
            title={'Add'}
            isDisabled={false}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
}
