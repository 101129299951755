import { useState, ChangeEvent, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormLoginOtpMutation, useFormLogoutMutation } from '@api/sessionSlice';
import LoginHeader from '@components/common/LoginHeader';
import { CommonButton } from '@components/common/CommonButton';

const FormLoginTotpPage = () => {
  const navigate = useNavigate();
  const [formLoginOtp] = useFormLoginOtpMutation();
  const [formLogout] = useFormLogoutMutation();
  const [totp, setTotp] = useState<string[]>(Array(6).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const location = useLocation();

  const handleChange = (elementIndex: number, event: ChangeEvent<HTMLInputElement>) => {
    let newTotp = [...totp];
    newTotp[elementIndex] = event.target.value;
    setTotp(newTotp);

    if (event.target.value.length === 1 && elementIndex < 5) {
      inputsRef.current[elementIndex + 1]?.focus();
    }

    if (newTotp.every((digit) => digit.length === 1)) {
      login(newTotp.join(''));
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    const pastedDigits = pastedData.split('');

    if (pastedDigits.length === 6 && pastedDigits.every((digit) => /\d/.test(digit))) {
      setTotp(pastedDigits);
      inputsRef.current[5]?.focus();
      login(pastedDigits.join(''));
    }
  };

  const handleKeyDown = (elementIndex: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && totp[elementIndex] === '') {
      inputsRef.current[elementIndex - 1]?.focus();
    }
  };

  const login = async (totp: string) => {
    // Submit the TOTP
    setIsSubmitting(true);
    setIsLoggingIn(true);
    let params = {
      verificationCode: totp,
    };
    const response: any = await formLoginOtp(params);
    setIsSubmitting(false);
    setIsLoggingIn(false);

    if (!response.error) {
      console.log('OTP Verified');

      let path = location.pathname;
      let redirectPath = path.includes('io-') ? '/io-create' : '/create';
      return navigate(redirectPath);
    }
  };

  const logout = async () => {
    setIsSubmitting(true);
    setIsLoggingOut(true);
    const response: any = await formLogout({});
    setIsSubmitting(false);
    setIsLoggingOut(false);
    let path = location.pathname;
    let redirectPath = path.includes('io-') ? '/io-create' : '/create';

    if (!response.error) {
      return navigate(redirectPath);
    }
  };

  return (
    <div className="md:flex flex-col items-center justify-center min-h-screen bg-white md:bg-gradient-to-b md:from-blue-300 md:to-blue-400">
      <div className="flex flex-col w-full h-full md:w-1/2 xl:w-5/12 gap-2 bg-white py-16 px-10 md:px-16 rounded-lg">
        <LoginHeader />

        <div className="flex flex-col items-start pb-4">
          <p className="font-bold text-sm">Verification code</p>
          <p className="text-grey-400 text-sm">Please check your email for the OTP</p>
        </div>
        <div className="flex justify-center gap-2.5 md:gap-4 pb-4">
          {totp.map((digit, index) => (
            <input
              key={index}
              type="text"
              name={`totp${index}`}
              value={digit}
              onPaste={handlePaste}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(index, event)}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown(index, event)
              }
              className="w-10 h-10 md:w-16 md:h-16 text-center border-grey-400 border-2 rounded-md"
              maxLength={1}
              pattern="\d*"
              ref={(ref) => (inputsRef.current[index] = ref)}
            />
          ))}
        </div>
        <CommonButton
          className="ml-0 w-full flex justify-center"
          variant="primary"
          type="submit"
          title={isLoggingIn ? 'Logging in...' : 'Login'}
          onClick={() => login(totp.join(''))}
          isDisabled={isSubmitting}
          isLoading={isLoggingIn}
        />
        <CommonButton
          className="ml-0 w-full flex justify-center"
          variant="secondary"
          type="submit"
          title={isLoggingOut ? 'Logging out...' : 'Logout'}
          onClick={() => logout()}
          isDisabled={isSubmitting}
          isLoading={isLoggingOut}
        />
      </div>
    </div>
  );
};

export default FormLoginTotpPage;
