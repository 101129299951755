import { useState } from 'react';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import LoginHeader from '@components/common/LoginHeader';
import { formLoginFormGeneralItems } from './FormLoginFormConfig';
import { useFormLoginMutation } from '@api/sessionSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { CommonButton } from '@components/common/CommonButton';

export default function FormLoginComponent() {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  const [formLogin] = useFormLoginMutation();

  const formMethods = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  });

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    const formValues: any = formMethods.getValues();
    let params = {
      email: formValues.email,
      password: formValues.password,
    };
    await formLogin(params);

    setIsSubmitting(false);
    let path = location.pathname;
    let redirectPath = path === '/io-form-login' ? '/io-form-login-otp' : '/form-login-otp';
    return navigate(redirectPath);
  };

  return (
    <div className="md:flex flex-col items-center justify-center min-h-screen bg-white md:bg-gradient-to-b md:from-blue-300 md:to-blue-400">
      <div className="flex flex-col w-full h-full md:w-1/2 xl:w-5/12 gap-2 bg-white py-16 px-10 md:px-16 rounded-lg">
        <LoginHeader />

        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <h1 className="mb-4 ml-1 text-center">Form Login</h1>
            <DynamicForm
              formFields={formLoginFormGeneralItems}
              formMethods={formMethods}
            ></DynamicForm>
          </div>
          <div className="flex flex-row flex-col pt-4 text-left">
            <CommonButton
              className="ml-0 w-full flex justify-center"
              variant="primary"
              type="submit"
              title={isSubmitting ? 'Logging in...' : 'Login'}
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export async function loader({ request }: any) {
  const accessTokenPayload = await getSessionUserFromAccessToken('SatisPlusFormAccessToken');

  let url = new URL(request.url);

  if (url.pathname === '/form-login') {
    if (accessTokenPayload?.otpVerified) {
      console.log('Valid session');

      return redirect('/create');
    } else if (accessTokenPayload && !accessTokenPayload.otpVerified) {
      return redirect('/form-login-otp');
    }
  } else if (url.pathname === '/io-form-login') {
    if (accessTokenPayload?.otpVerified) {
      console.log('Valid session');

      return redirect('/io-create');
    } else if (accessTokenPayload && !accessTokenPayload.otpVerified) {
      return redirect('/io-form-login-otp');
    }
  } else if (url.pathname === '/form-login-otp') {
    if (accessTokenPayload?.otpVerified) {
      return redirect('/create');
    } else if (!accessTokenPayload) {
      return redirect('/form-login');
    }
  } else if (url.pathname === '/io-form-login-otp') {
    if (accessTokenPayload?.otpVerified) {
      return redirect('/io-create');
    } else if (!accessTokenPayload) {
      return redirect('/io-form-login');
    }
  } else if (url.pathname === '/io-create') {
    if (!accessTokenPayload?.otpVerified) {
      console.log('No valid session, redirecting to login page');

      return redirect(`/io-form-login`);
    }
  } else {
    if (!accessTokenPayload?.otpVerified) {
      console.log('No valid session, redirecting to login page');

      return redirect(`/form-login`);
    }
  }

  return '';
}
