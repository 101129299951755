import { useEffect, useState } from 'react';
import {
  useCreateProductionOrderMutation,
  useDeleteProductionOrderQueueMutation,
  useDownloadProductionOrder,
  useSendProductionOrderQueueMutation,
} from '@api/productionOrderSlice';

import {
  CommonButton,
  CommonButtonGroup,
  IconButton,
  LoadingButtonComponent,
} from '@components/common/CommonButton';
import { useDispatch } from 'react-redux';
import { deleteProductionOrder } from '../directionsqueue/DeleteProductionOrder';
import DiaryModal from './modals/DiaryModal';
import UploadCreateExcelModal from '@pages/portfolios/monikers/modals/UploadCreateExcelModal';
import DownloadDateRangeModal from '@pages/portfolios/monikers/modals/DownloadDateRangeModal';
import AddScamEnablersModal from './modals/AddScamEnablersModal';
import SubmissionsDetailsModal from './modals/SubmissionsDetailsModal';
import ConfirmDeleteScamEnablerModal from './modals/ConfirmDeleteScamEnablerModal';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import CreateReportComponent from './CreateReportPage/CreateReportComponent';
import AddScamEnablersModalOld from './modals/AddScamEnablersModalOld';
import ProductionOrderDeleteQueueModal from '../directionsqueue/modals/ProductionOrderDeleteQueueModal';
import { useGetConfigQuery } from '@api/configSliceApi';

type FormButtonProps = {
  text: string;
  shade?: string;
  action?: boolean;
  loading?: boolean;
  asDiv?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function DownloadReportsButton() {
  const [isDateRangeModalOpen, setDateRangeModalOpen] = useState(false);
  const [typeOfDownload, setTypeOfDownload] = useState('');

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'download',
            title: 'Daily Returns',
            disabled: false,
            onClick: () => {
              setDateRangeModalOpen(true);
              setTypeOfDownload('paymentDailyReturns');
            },
          },
          {
            key: 'sms',
            title: 'SMS',
            disabled: false,
            onClick: async () => {
              setTypeOfDownload('bankSms');
              setDateRangeModalOpen(true);
            },
          },
          // {
          //   key: 'downloadStat',
          //   title: 'Statistics',
          //   disabled: false,
          //   onClick: () => {
          //     setDownloadDailyReturnsOpen(true);
          //     setTypeOfDownload('statistics');
          //   },
          // },
        ]}
        title="Download"
        variant="secondary"
        allowedPermissions={['reports.banks.download']}
      />
      <DownloadDateRangeModal
        isOpen={isDateRangeModalOpen}
        onClose={() => {
          setDateRangeModalOpen(false);
        }}
        downloadType={typeOfDownload}
      />
    </>
    // <CommonButton
    //   isDisabled={false}
    //   variant="text"
    //   title={`Download`}
    //   onClick={() => console.log('download')}
    // />
  );
}

export function DownloadSingleReportButton({ reportId }: { reportId: string }) {
  return (
    <IconButton
      icon="download"
      onClick={() => {
        // TODO: implement downloading of the report
        console.log(`Downloading report ${reportId}`);
      }}
      isDisabled={false}
    />
  );
}

export function AddReportButton({ refetch }: { refetch: () => void }) {
  const [showModal, setShowModal] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const navigate = useNavigate();
  const { data: viewConfig } = useGetConfigQuery('view', {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const isOldView = viewConfig?.config?.config?.isOldView ?? true;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitted = (submissionId: string) => {
    setShowModal(false);
    if (refetch) refetch();
  };

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'manual',
            title: 'Manual Entry',
            disabled: false,
            onClick: () => {
              if (isOldView) {
                setShowModal(true);
              } else {
                navigate('/admin/reports/create?route=reports');
              }
            },
          },
          // {
          //   key: 'csv',
          //   title: 'Upload List',
          //   disabled: false,
          //   onClick: () => {
          //     setDownloadOpen(true);
          //   },
          // },
        ]}
        isDisabled={false}
        variant="primary"
        title={`Add Reports`}
        allowedPermissions={['portfolios.scamenablers.create']}
      />

      <Modal open={showModal} onClose={handleCloseModal}>
        <ModalLayout
          title={'Add Report - Manual Entry'}
          handleCloseModal={handleCloseModal}
          alignment="center"
          size="medium-form"
          overflowClass=""
        >
          <CreateReportComponent
            showHeader={false}
            handleCloseModal={handleCloseModal}
            handleSubmitted={(submissionId: string) => handleSubmitted(submissionId)}
            isFormCreateReport={false}
          />
        </ModalLayout>
      </Modal>

      {downloadOpen && (
        <UploadCreateExcelModal
          id={'fileUpload'}
          isOpen={downloadOpen}
          onClose={() => setDownloadOpen(false)}
          callback={refetch}
        />
      )}
    </>
  );
}

export function GenerateProductionOrderButton({
  scamEnablerIds,
  handleCloseModal,
  callback,
  poQueueIds,
}: {
  scamEnablerIds: Array<string>;
  handleCloseModal: () => void;
  callback: () => void;
  poQueueIds: Array<string>;
}) {
  const [createProductionOrder] = useCreateProductionOrderMutation();
  const [createProductionOrderQueue] = useSendProductionOrderQueueMutation();
  const downloadProductionOrder = useDownloadProductionOrder();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      let tempPOQueueIds = poQueueIds;
      if (!poQueueIds || poQueueIds.length === 0) {
        let poQueuResponse = await createProductionOrderQueue({
          scamEnablerIds: scamEnablerIds,
        });
        if ('error' in poQueuResponse) {
          console.log('error', poQueuResponse.error);
        } else {
          tempPOQueueIds = poQueuResponse.data.poQueueIds;
        }
      }
      if (tempPOQueueIds.length === 0) {
        throw new Error('No PO Queue Ids');
      }
      let response = await createProductionOrder({
        scamEnablerIds: scamEnablerIds,
        poQueueIds: tempPOQueueIds,
      });

      if ('error' in response) {
        console.log('error', response.error);
        throw new Error(JSON.stringify(response.error));
      } else if (response.data) {
        downloadProductionOrder(response.data.productionOrderId, 'v1', false);

        setIsLoading(false);
        callback();
        handleCloseModal();
      }
    } catch (e) {
      setIsLoading(false);
      handleCloseModal();
      console.log('error', e);
    }
  };
  return (
    <LoadingButtonComponent
      isLoading={isLoading}
      title="Generate Production Order"
      onClick={handleClick}
    />
  );
}

export function DownloadProductionOrderButton({
  order,
  requireAdditionalFiles,
}: {
  order: {
    productionOrderId: string;
    poReferenceNo: string;
    createdAt: number;
    createdBy: any;
  };
  requireAdditionalFiles: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const downloadPO = useDownloadProductionOrder();
  return (
    <IconButton
      icon="download"
      isDisabled={false}
      isLoading={isLoading}
      size="20px"
      onClick={async () => {
        setIsLoading(true);
        await downloadPO(order.productionOrderId, 'v1', requireAdditionalFiles);
        setIsLoading(false);
      }}
    />
  );
}

// export function FormButton({ text, shade, action, loading, asDiv, ...props }: FormButtonProps) {
//   let buttonClass = 'font-semibold px-4 py-3 rounded ';
//   switch (shade) {
//     case 'primary':
//     case 'dark':
//       buttonClass += `
//         text-white
//         bg-blue-500
//         hover:bg-[var(--blue-500-opacity)]
//         active:bg-blue-300 active:ring-2 active:ring-blue-600 active:text-[var(--blue-600)]
//       `;
//       break;
//     case 'secondary':
//     case 'light':
//       buttonClass += `
//         text-blue-500
//         bg-blue-300
//         hover:bg-blue-400 hover:text-white
//         active:bg-blue-300 active:ring-2 active:ring-blue-600 active:text-[var(--blue-600)]
//       `;
//       break;
//     case 'warning':
//       buttonClass += `
//         bg-red text-white
//       `;
//       break;
//     default:
//       if (action) buttonClass = `font-semibold text-sm text-blue-400`;
//       break;
//   }

//   if (props.disabled || loading) buttonClass += 'bg-black opacity-20 cursor-not-allowed hover:none';
//   if (props.className) buttonClass += ` ${props.className}`;

//   if (asDiv) {
//     return <div className={buttonClass}>{text}</div>;
//   }

//   return (
//     <button {...props} className={buttonClass}>
//       {loading ? (
//         <div className="flex flex-row items-center gap-2">
//           <ThemeProvider theme={theme}>
//             <CircularProgress size={14} color="primary" />{' '}
//           </ThemeProvider>
//           {text}
//         </div>
//       ) : (
//         text
//       )}
//     </button>
//   );
// }

export function DownloadProductionOrderSingleButton({
  productionOrderId,
}: {
  productionOrderId: string;
}) {
  const downloadProductionOrder = useDownloadProductionOrder();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <IconButton
      onClick={async () => {
        setIsLoading(true);
        await downloadProductionOrder(productionOrderId, 'v1', true);
        setIsLoading(false);
      }}
      icon="download"
      isLoading={isLoading}
      isDisabled={productionOrderId ? false : true}
    />
  );
}

export function OpenDiary({ callback, report }: { callback: () => void; report: any }) {
  const [diaryOpen, setDiaryOpen] = useState(false);
  const onAssignClose = () => {
    setDiaryOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setDiaryOpen(true)}
        icon="diary"
        isLoading={false}
        isDisabled={false}
      />
      {diaryOpen && (
        <DiaryModal
          isOpen={diaryOpen}
          handleClose={() => {
            setDiaryOpen(false);
          }}
          reportId={report._id}
          reportNum={report.reportNumber}
        />
      )}
    </>
  );
}

export function DeleteProductionOrderQueueButton({
  selectedRows,
  view,
  buttonType,
  setShowErrorMessage,
  isDisabled,
  callback,
}: {
  selectedRows: any[];
  view: string;
  buttonType?: string;
  setShowErrorMessage?: any;
  isDisabled: boolean;
  callback: () => void;
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteProductionOrderQueue] = useDeleteProductionOrderQueueMutation();
  const dispatch = useDispatch();

  const handleClick = () => {
    setDeleteModalOpen(true);
  };

  const handleDeletePO = async () => {
    let poQueueIds = selectedRows.map((poQueue) => poQueue.poQueueId);
    await deleteProductionOrder(poQueueIds, view, dispatch, deleteProductionOrderQueue);
    callback();
    setDeleteModalOpen(false);
  };

  const onCloseModal = () => {
    setDeleteModalOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      {(!buttonType || buttonType === 'text') && (
        <CommonButton
          variant="warning"
          title={`Delete From Queue`}
          onClick={handleClick}
          isLoading={isLoading}
          isDisabled={isDisabled}
          allowedPermissions={[
            'directionsQueue.scamenablers.delete',
            'directionsQueue.banks.delete',
          ]}
          onMouseEnter={() => {
            setShowErrorMessage(true);
          }}
          onMouseLeave={() => {
            if (setShowErrorMessage) setShowErrorMessage(false);
          }}
        />
      )}
      {buttonType === 'icon' && (
        <IconButton
          onClick={() => {
            handleClick();
          }}
          icon="delete"
          isLoading={isLoading}
          isDisabled={isDisabled}
          allowedPermissions={[
            'directionsQueue.scamenablers.delete',
            'directionsQueue.banks.delete',
          ]}
        />
      )}
      {deleteModalOpen && (
        <ProductionOrderDeleteQueueModal
          isOpen={deleteModalOpen}
          selectedRows={selectedRows}
          view={view}
          handleDeletePO={handleDeletePO}
          onClose={onCloseModal}
        />
      )}
    </>
  );
}

export function AddScamEnablerButton({
  reportId,
  callback,
  report,
  currentUser,
  allowedPermissions,
}: {
  reportId: string;
  callback: any;
  report: any;
  currentUser: any;
  allowedPermissions: any;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isOldView = false;
  return (
    <>
      <CommonButton
        onClick={() => {
          setIsModalOpen(true);
        }}
        variant="primary"
        type="button"
        // isLoading={isLoading}
        isDisabled={false}
        title="Add Scam Enabler"
        className="w-full text-center flex align-middle justify-center"
        allowedPermissions={allowedPermissions}
      />

      {isModalOpen && (
        <>
          {isOldView && (
            <AddScamEnablersModalOld
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              reportId={reportId}
              report={report}
              currentUser={currentUser}
              callback={callback}
            />
          )}

          {!isOldView && (
            <AddScamEnablersModal
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              reportId={reportId}
              report={report}
              currentUser={currentUser}
              callback={callback}
            />
          )}
        </>
      )}
    </>
  );
}

export function InformationButton({ size, click }: { size: string; click: any }) {
  return <IconButton size={size} icon="info" type="button" isDisabled={false} onClick={click} />;
}

export function GetSubmissionButton({
  reportHistoryData,
  reportNumber,
}: {
  reportHistoryData: any;
  reportNumber: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <CommonButton
        isDisabled={false}
        variant="text"
        title="View Details"
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
      <SubmissionsDetailsModal
        assignOpen={isModalOpen}
        onAssignClose={() => {
          setIsModalOpen(false);
        }}
        callback={() => {}}
        data={reportHistoryData}
        reportNumber={reportNumber}
      />
    </>
  );
}

export function DeleteScamEnablerButton({
  selectedScamEnabler,
  reportId,
  type,
  refetch,
}: {
  selectedScamEnabler: any;
  reportId: string;
  type: string;
  refetch: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <IconButton
        onClick={() => {
          setIsModalOpen(true);
        }}
        icon="delete"
        isDisabled={false}
      />
      <ConfirmDeleteScamEnablerModal
        confirmDeleteOpen={isModalOpen}
        onConfirmDeleteClose={() => {
          setIsModalOpen(false);
        }}
        callback={() => {
          if (refetch) refetch();
        }}
        selectedScamEnabler={selectedScamEnabler}
        reportId={reportId}
        type={type}
      />
    </>
  );
}
