import AccessControl from '@components/common/AccessControl';
import MonikerTable from './monikers/DirectionsQueueMonikerTable';
import BankTable from './banks/DirectionsQueueBankTable';
import { Tab, Tabs } from '@mui/material';
import HeaderComponent from '@components/common/HeaderComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { checkPermissions } from '@utils/utils';

const DirectionsQueuePage = () => {
  const { type } = useParams<{ type: string }>();

  const getTabState = () => {
    if (type === 'banks') {
      return 1;
    } else {
      return 0;
    }
  };
  const [selectedTab, setTab] = useState(getTabState());
  const navigate = useNavigate();

  useEffect(() => {
    if (checkPermissions(['directionsQueue.banks.viewproductionorders']) && !checkPermissions(['directionsQueue.scamenablers.viewproductionorders'])) {
      navigate(`/admin/directions-queue/banks`);
    }
  }, [type]);

  const handleChange = (event: any, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) {
      navigate(`/admin/directions-queue/monikers`);
    } else if (newValue === 1) {
      navigate(`/admin/directions-queue/banks`);
    }
  };

  return (
    <>
      <HeaderComponent title={'Directions Queue'} />
      <div className="p-2">
        <AccessControl
          type="page"
          allowedPermissions={[
            'directionsQueue.scamenablers.viewproductionorders',
            'directionsQueue.banks.viewproductionorders',
          ]}
        >
          <Tabs
            className=""
            value={selectedTab}
            onChange={handleChange}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            <Tab
              sx={{
                textTransform: 'none',
                padding: '8px',
                display:
                  checkPermissions(['directionsQueue.scamenablers.viewproductionorders']) || 'none',
              }}
              label={
                <div className="flex flex-row justify-center items-center">
                  <p
                    className={`${selectedTab === 0 ? 'text-blue-500 font-bold bg-blue-200' : 'text-blue-400 font-bold'} px-4 py-2 rounded-md`}
                  >
                    Monikers (Reported)
                  </p>
                </div>
              }
            />
            <Tab
              sx={{
                textTransform: 'none',
                padding: '8px',
                display: checkPermissions(['directionsQueue.banks.viewproductionorders']) || 'none',
              }}
              label={
                <div className="flex flex-row justify-center items-center">
                  <p
                    className={`${selectedTab === 1 ? 'text-blue-500 font-bold bg-blue-200' : 'text-blue-400 font-bold'} px-4 py-2 rounded-md`}
                  >
                    Banks
                  </p>
                  {/* <div className="border-r-2 border-black h-8"></div> */}
                </div>
              }
            />
          </Tabs>
        </AccessControl>

        {!type || (type === 'monikers' && <MonikerTable />)}
        {type === 'banks' && <BankTable />}
      </div>
    </>
  );
};

export default DirectionsQueuePage;
