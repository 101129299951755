import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import DynamicForm from '@components/common/DynamicForm';
import {
  submissionDetailsGeneralInfo,
  submissionDetailsScamEnablerBank,
  submissionDetailsScamEnablerMoniker,
  submissionDetailsScamEnablerPaynow,
} from '../ReportFormConfig';
import { useEffect, useState } from 'react';
import { IconButton } from '@components/common/CommonButton';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import cloneDeep from 'lodash.clonedeep';
import { convertMsToYYYYMMDD } from '@utils/utils';
import AccessControl from '@components/common/AccessControl';
import ScamEnablersDisplay from '../components/ScamEnablersDisplay';
import VictimsDisplay from '../components/VictimsDisplay';

function SubmissionDetailFinancialInstitute({
  submission,
  index,
}: {
  submission: any;
  index: number;
}) {
  const formMethods = useForm({ defaultValues: submission, mode: 'onChange' });

  useEffect(() => {
    formMethods.reset(submission);
  }, [submission]);

  return (
    <>
      <div className="flex">
        <h4 className="font-bold text-sm text-grey-600 pr-1">Scam Enabler {index}</h4>
        {submission.deleted === true && (
          <p className="font-bold text-sm text-red italic">(deleted)</p>
        )}
      </div>
      <DynamicForm
        data={submission}
        formFields={
          submission.platform === 'Paynow'
            ? submissionDetailsScamEnablerPaynow
            : submissionDetailsScamEnablerBank
        }
        isEditable={false}
        formMethods={formMethods}
      />
    </>
  );
}

function SubmissionDetailSubmission({
  submissionData,
  isActive,
}: {
  submissionData: any;
  isActive: boolean;
}) {
  const formMethods = useForm({ defaultValues: submissionData, mode: 'onChange' });

  const transformData = (data: any) => {
    // For Monikers, we need to group by platform
    const platformGroups: Record<string, any[]> = {};
    // Group monikers by platform
    if (data.scamEnablerSubmissions && data.scamEnablerSubmissions.length > 0) {
      data.scamEnablerSubmissions.forEach((moniker: any) => {
        const platform = moniker.platform || 'Unknown';
        if (!platformGroups[platform]) {
          platformGroups[platform] = [];
        }
        platformGroups[platform].push(moniker);
      });
    }

    // Convert the grouped monikers to the expected format - one entry per platform with an array of rows
    const monikers = Object.entries(platformGroups).map(([platform, items]) => ({
      platform,
      rows: items,
    }));
    /*
    // Filter financial institute submissions into banks and fintech based on submission type
    const banks = [];
    const fintech = [];
    
    if (data.financialInstituteSubmissions && data.financialInstituteSubmissions.length > 0) {
      // Filter Payment type for banks
      const bankSubmissions = data.financialInstituteSubmissions.filter(
        (submission: any) => submission.type === "Payment"
      );
      
      if (bankSubmissions.length > 0) {
        banks.push({ rows: bankSubmissions });
      }
      
      // Filter FintechCrypto type for fintech
      const fintechSubmissions = data.financialInstituteSubmissions.filter(
        (submission: any) => submission.type === "FintechCrypto"
      );
      
      if (fintechSubmissions.length > 0) {
        fintech.push({ rows: fintechSubmissions });
      }
    }*/
    const financialInstitutes =
      data.financialInstituteSubmissions && data.financialInstituteSubmissions.length > 0
        ? [{ rows: data.financialInstituteSubmissions }]
        : [];

    const paynow =
      data.paynowSubmissions && data.paynowSubmissions.length > 0
        ? [{ rows: data.paynowSubmissions }]
        : [];
    const victims =
      data.victimSubmissions && data.victimSubmissions.length > 0
        ? [{ rows: data.victimSubmissions }]
        : [];

    return {
      scamEnablers: {
        Monikers: monikers,
        FinancialInstitutes: financialInstitutes,
        Paynow: paynow,
        Victims: victims,
      },
    };
  };

  // Effect to register the data with the form
  useEffect(() => {
    // Transform the data inside the effect
    const transformedData = transformData(submissionData);

    // Register data with the form
    if (transformedData?.scamEnablers) {
      if (transformedData.scamEnablers.Monikers) {
        transformedData.scamEnablers.Monikers.forEach((moniker: any, index: number) => {
          if (moniker.rows && Array.isArray(moniker.rows)) {
            formMethods.setValue(`scamEnablers[Monikers][${index}].rows`, moniker.rows);
          }
        });
      }

      // Register FinancialInstitutes data
      if (transformedData.scamEnablers.FinancialInstitutes) {
        transformedData.scamEnablers.FinancialInstitutes.forEach((bank: any, index: number) => {
          if (bank.rows && Array.isArray(bank.rows)) {
            formMethods.setValue(`scamEnablers[FinancialInstitutes][${index}].rows`, bank.rows);
          }
        });
      }

      // Register Paynow data
      if (transformedData.scamEnablers.Paynow) {
        transformedData.scamEnablers.Paynow.forEach((paynow: any, index: number) => {
          if (paynow.rows && Array.isArray(paynow.rows)) {
            formMethods.setValue(`scamEnablers[Paynow][${index}].rows`, paynow.rows);
          }
        });
      }

      if (transformedData.scamEnablers.Victims) {
        transformedData.scamEnablers.Victims.forEach((victim: any, index: number) => {
          if (victim.rows && Array.isArray(victim.rows)) {
            formMethods.setValue(`scamEnablers[Victims][${index}].rows`, victim.rows);
          }
        });
      }
    }
    console.log('transformedData', transformedData);
  }, [formMethods, submissionData]);

  // Transform the data again for passing to component
  const transformedData = transformData(submissionData);

  return (
    <div
      className={clsx('h-full overflow-auto', {
        hidden: !isActive,
      })}
    >
      <h3 className="text-blue-400 font-bold">General Information</h3>
      <DynamicForm data={submissionData} formFields={submissionDetailsGeneralInfo} />

      <h3 className="text-blue-400 font-bold pb-4">Scam Enablers</h3>
      <div className="flex flex-col gap-2">
        {submissionData?.scamEnablerSubmissions && (
          <AccessControl type="component" allowedPermissions={['reports.scamenablers.view']}>
            {submissionData?.scamEnablerSubmissions.map((submission: any, index: number) => (
              <div key={`scamEnablerSubmissions-${index}`}>
                <h4 className="font-bold text-sm text-grey-600">Scam Enabler {index + 1}</h4>
                <DynamicForm
                  data={{ ...submission, type: 'Monikers' }}
                  formFields={submissionDetailsScamEnablerMoniker}
                  isEditable={false}
                  formMethods={formMethods}
                />
              </div>
            ))}
          </AccessControl>
        )}
        {submissionData?.financialInstituteSubmissions && (
          <AccessControl type="component" allowedPermissions={['reports.banks.view']}>
            {submissionData?.financialInstituteSubmissions.map((submission: any, index: number) => (
              <SubmissionDetailFinancialInstitute
                key={`financialInstituteSubmissions-${index}`}
                submission={submission}
                index={index + 1 + (submissionData?.scamEnablerSubmissions?.length || 0)}
              />
            ))}
          </AccessControl>
        )}
        {submissionData?.paynowSubmissions && (
          <AccessControl type="component" allowedPermissions={['reports.paynows.view']}>
            {submissionData?.paynowSubmissions.map((submission: any, index: number) => (
              <SubmissionDetailFinancialInstitute
                key={`paynowSubmissions-${index}`}
                submission={submission}
                index={
                  index +
                  1 +
                  (submissionData?.scamEnablerSubmissions?.length || 0) +
                  (submissionData?.financialInstituteSubmissions?.length || 0)
                }
              />
            ))}
          </AccessControl>
        )}
      </div>
      <h3 className="text-black-400 font-bold mb-4">Scam Enablers</h3>
      <ScamEnablersDisplay formMethods={formMethods} formValues={transformedData} />
      <VictimsDisplay formMethods={formMethods} formValues={transformedData} />
    </div>
  );
}

const SubmissionsDetailsModal = ({
  assignOpen,
  onAssignClose,
  callback,
  data,
  reportNumber,
}: {
  assignOpen: boolean;
  onAssignClose: () => void;
  callback: () => void;
  data: any;
  reportNumber: string;
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [processedData, setProcessedData] = useState<any>([]);
  useEffect(() => {
    let newData: any = [];
    data.forEach((submission: any) => {
      newData.push(processSubmission(submission));
    });
    setProcessedData(newData);
  }, [data]);

  const processSubmission = (submission: any) => {
    let processedSubmission = cloneDeep(submission);
    if (processedSubmission?.financialInstituteSubmissions?.length > 0) {
      processedSubmission.financialInstituteSubmissions.forEach((submission: any) => {
        let transactions: any = [];
        if (submission.transactions && submission.transactions.length > 0) {
          submission.transactions.forEach((t: any) => {
            transactions.push({
              dateOfTransfer: t.dateOfTransfer ? convertMsToYYYYMMDD(t.dateOfTransfer) : '',
              amountLoss: `$${t?.amountLossInSGD?.toFixed(2) || t?.amountLoss.toFixed(2) || 0} ${t?.currency === 'SGD' ? '' : `(${t?.amountLoss.toFixed(2) + ' ' + t?.currency})`}`,
              transactionType: t.transactionType,
            });
          });
        }

        submission.transactions = transactions;
      });
    }
    return processedSubmission;
  };

  return (
    <Modal open={assignOpen} onClose={onAssignClose}>
      <ModalLayout
        title={reportNumber}
        handleCloseModal={onAssignClose}
        alignment="right-large-gray"
        size="full-gray"
      >
        <div className="overflow-auto h-[84vh] flex flex-col -mt-6">
          <div className="flex justify-between align-middle mb-5">
            <h4 className="text-md font-semibold text-blue-500">Submissions</h4>

            <div className="flex flex-row align-middle justify-center items-center gap-2">
              <IconButton
                isDisabled={activeTab === 0}
                icon="left-arrow"
                size="18px"
                className="bg-blue-200 p-1 rounded-md"
                onClick={() => setActiveTab(activeTab - 1)}
              />
              <p className="text-sm text-grey-600">
                <span className="font-semibold">{activeTab + 1}</span> of {data?.length || 0}
              </p>
              <IconButton
                isDisabled={activeTab + 1 === data.length}
                icon="right-arrow"
                size="18px"
                className="bg-blue-200 p-1 rounded-md"
                onClick={() => setActiveTab(activeTab + 1)}
              />
            </div>
          </div>
          {processedData.map((submission: any, index: number) => (
            <SubmissionDetailSubmission
              key={index}
              isActive={activeTab === index}
              submissionData={{
                ...submission,
                reportNumber: reportNumber,
              }}
            />
          ))}
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default SubmissionsDetailsModal;
