import { useCreateReportCsvV2Mutation } from '@api/reportsSlice';
import { CommonButton } from '@components/common/CommonButton';
import FileUpload from '@components/common/FileUpload';
import ModalLayout from '@components/layouts/ModalLayout';
import { Badge, Checkbox, FormControlLabel, Modal, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import csv from 'csvtojson';
import cloneDeep from 'lodash.clonedeep';
import { platform, scamType } from '@interface/constant';
import { cleanUpUserId, cleanUpWhatsappNumber, generateReportNumber } from '@utils/utils';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal from './UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal';

const validColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '5%',
  },
  {
    title: 'Report No.',
    dataIndex: 'reportNumber',
    key: 'reportNumber',
    width: '15%',
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    width: '15%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '20%',
  },
  {
    title: 'ProfileName',
    dataIndex: 'profileName',
    key: 'profileName',
    width: '15%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '10%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '15%',
  },
];

const invalidColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Report No.',
    dataIndex: 'reportNumber',
    key: 'reportNumber',
    width: '12%',
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    width: '12%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '16%',
  },
  {
    title: 'ProfileName',
    dataIndex: 'profileName',
    key: 'profileName',
    width: '15%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '10%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '15%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'reason',
    width: '15%',
    render: (text: string, record: any, index: number) => <p className="text-red">{text}</p>,
  },
];
const UploadCreateExcelModal = ({
  id,
  isOpen,
  onClose,
  callback,
}: {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  callback: () => void;
}) => {
  const formMethods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [createReportCsvV2] = useCreateReportCsvV2Mutation();

  const [validCsvData, setValidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [invalidCsvData, setInvalidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [tabValue, setTabValue] = useState('valid');
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(true); // Default checked state
  const [updateAssessmentFindingsOpen, setUpdateAssessmentFindingsOpen] = useState(false);
  const [selectedScamEnablers, setSelectedScamEnablers] = useState<any[]>([]);
  const [isAddReportShown, setIsAddReportShown] = useState(true);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const validate = (element: any) => {
    let pattern = /^(?=.{13,15}$)([A-Z]{1,3}\/\d{4,8}\/\d+|ASC\/\d{4}\/\d{2}\/\d{2})$/;
    console.log('element.reportNumber', element.reportNumber);
    if (element.reportNumber && !pattern.test(element.reportNumber)) {
      element.errorMessage = 'Invalid Report Number';
      console.log('element', element);
      return element;
    }

    if (!element.userId || element.userId.trim() === '') {
      element.errorMessage = 'User ID is required';
      return element;
    }

    let allowedPlatform = platform;
    if (element.platform) {
      const matchingPlatform = allowedPlatform.find(
        (p) => p.toLowerCase() === element.platform.toLowerCase(),
      );

      if (matchingPlatform) {
        element.platform = matchingPlatform;
      } else {
        element.errorMessage = 'Invalid Platform';
        return element;
      }
    }

    let allowedScamType = scamType;
    if (element.scamType) {
      const matchingScamType = allowedScamType.find(
        (type) => type.toLowerCase() === element.scamType.toLowerCase(),
      );
      if (matchingScamType) {
        element.scamType = matchingScamType;
      } else {
        element.errorMessage = 'Invalid Scam Type';
        return element;
      }
    }

    const userId =
      element.platform === 'Whatsapp' ? cleanUpWhatsappNumber(element.userId) : element.userId;
    if (element.platform === 'Whatsapp' && !userId) {
      element.errorMessage = 'Invalid Whatsapp number';
      return element;
    }

    element.userId = cleanUpUserId(userId, element.platform || '');

    return element;
  };

  const setJSONData = (jsonData: any[]) => {
    const { validArray, invalidArray } = jsonData.reduce(
      (acc, element, index) => {
        element = validate(element);

        if (element.errorMessage) {
          acc.invalidArray.push(element);
        } else {
          if (!element.reportNumber) {
            element.reportNumber = generateReportNumber('CSV', index);
          }
          acc.validArray.push(element);
        }

        return acc;
      },
      { validArray: [], invalidArray: [] },
    );

    setValidCsvData(validArray);
    setInvalidCsvData(invalidArray);
  };

  const showCsvContent = async () => {
    let uploadedFile = cloneDeep(formMethods.getValues(id));
    if (!uploadedFile[0]?.fileContent) {
      setValidCsvData([]);
      setInvalidCsvData([]);
      return;
    }
    uploadedFile[0].fileContent = uploadedFile[0].fileContent.split(',')[1];
    let csvData = Buffer.from(uploadedFile[0].fileContent, 'base64').toString('utf8');
    let jsonData = await csv().fromString(csvData);
    setJSONData(jsonData);
  };

  const onUpload = async () => {
    setIsLoading(true);
    let response: any = await createReportCsvV2(validCsvData);

    if (!response.error && response?.data?.scamEnablers) {
      dispatch(
        showAlert({
          type: 'success',
          message: `${response.data.scamEnablers.length} new moniker(s) added successfully.`,
        }),
      );
      if (isChecked) {
        setSelectedScamEnablers(response.data.scamEnablers);
        setIsAddReportShown(false);
        let isScamEnablersValid = response.data.scamEnablers.filter((scamEnabler: any) => {
          return (
            scamEnabler.outcome === 'Pending Review' ||
            scamEnabler.outcome === 'Not Terminated' ||
            scamEnabler.outcome === 'No Action Required'
          );
        });
        if (isScamEnablersValid.length > 0) {
          setUpdateAssessmentFindingsOpen(true);
        } else {
          dispatch(
            showAlert({
              type: 'success',
              message: (
                <div>
                  <p>{`${response.data.scamEnablers.length} new moniker(s) added successfully.`}</p>
                  <p className="italic pt-1">
                    As all monikers added are <b>not</b> Pending Review, Not Terminated or
                  </p>
                  <p className="italic">
                    No Action Required. Bulk update of assessment findings will not be eligible.
                  </p>
                </div>
              ),
            }),
          );
          if (callback) callback();
          onClose();
        }
      } else {
        if (callback) callback();
        onClose();
      }
    }
    setIsLoading(false);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/assets/template/csv_upload_template.csv';
    link.download = 'SATIS+ CSV Upload Template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Modal open={isOpen && isAddReportShown} onClose={onClose}>
        <ModalLayout
          title="Add Report - Upload List"
          handleCloseModal={onClose}
          alignment="center"
          size="large"
          overflowClass="overflow-hidden"
        >
          <div>
            <CommonButton
              className="pb-3"
              title="Download CSV Template"
              variant="text-blue"
              onClick={handleDownloadTemplate}
              type="button"
            />
          </div>
          <form>
            <FileUpload
              id={id}
              control={formMethods.control}
              setValue={formMethods.setValue}
              getValues={formMethods.getValues}
              onClose={onClose}
              fileType={['text/csv']}
              maxFiles={1}
              callback={showCsvContent}
              allowPaste={false}
            />
          </form>
          {((validCsvData && validCsvData.length > 0) ||
            (invalidCsvData && invalidCsvData.length > 0)) && (
            <>
              <div className="flex justify-between items-center pb-4">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  TabIndicatorProps={{ style: { backgroundColor: '#255662' } }}
                >
                  <Tab
                    label={
                      <div className="flex items-center gap-4">
                        <p
                          className={`font-bold ${tabValue === 'valid' ? 'text-blue-500' : 'text-blue-400'}`}
                          style={{ textTransform: 'none' }}
                        >
                          Successful
                        </p>
                        <Badge color="success" badgeContent={validCsvData.length || 0} max={999} />
                      </div>
                    }
                    value="valid"
                  />
                  <Tab
                    label={
                      <div className="flex items-center gap-4">
                        <p
                          className={`font-bold ${tabValue === 'invalid' ? 'text-blue-500' : 'text-blue-400'}`}
                          style={{ textTransform: 'none' }}
                        >
                          Error
                        </p>
                        <Badge color="error" badgeContent={invalidCsvData.length || 0} max={999} />
                      </div>
                    }
                    value="invalid"
                  />
                </Tabs>
                <div className="flex flex-col">
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                    label={
                      <span className="text-grey-900">
                        Do you want to bulk assess the monikers?
                      </span>
                    }
                  />
                  <p className="text-grey-600">
                    Assessment can be done after uploading successful rows
                  </p>
                </div>
              </div>
              {tabValue === 'valid' && (
                <div className="h-[40vh] overflow-y-auto">
                  <Table
                    rowKey="userId"
                    dataSource={cloneDeep(validCsvData)}
                    columns={validColumns}
                    pagination={false}
                    sticky
                  />
                </div>
              )}
              {tabValue === 'invalid' && (
                <div className="h-[40vh] overflow-y-auto">
                  <Table
                    rowKey="userId"
                    dataSource={cloneDeep(invalidCsvData)}
                    columns={invalidColumns}
                    pagination={false}
                    sticky
                  />
                </div>
              )}
            </>
          )}
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
            <CommonButton
              isLoading={isLoading}
              variant="primary"
              title="Save"
              onClick={onUpload}
              isDisabled={validCsvData.length <= 0}
            />
          </div>
        </ModalLayout>
      </Modal>
      {updateAssessmentFindingsOpen && (
        <UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal
          selectedScamEnablers={selectedScamEnablers}
          isOpen={updateAssessmentFindingsOpen}
          handleClose={onClose}
          callback={callback}
        />
      )}
    </>
  );
};

export default UploadCreateExcelModal;
