import DynamicTable from '@components/common/DynamicTable';
import { filterEmptyRows } from '@utils/utils';
import { getFormItemsDisplay } from '../CreateReportPage/AdminCreateReportFormConfig';

const ScamEnablersDisplay = ({
  formMethods,
  formValues,
}: {
  formMethods: any;
  formValues: any;
}) => {
  let financialInstituteRecords = filterEmptyRows(formValues?.scamEnablers?.FinancialInstitutes);
  let paynowRecords = filterEmptyRows(formValues?.scamEnablers?.Paynow);
  let monikerRecords = filterEmptyRows(formValues?.scamEnablers?.Monikers);

  const getPlatformPath = (index: number, selectedCategory: string) => {
    return `scamEnablers[${selectedCategory}][${index}].platform`;
  };

  const getPlatformForIndex = (index: number, selectedCategory: string) => {
    return formMethods.getValues(getPlatformPath(index, selectedCategory));
  };

  return (
    <div className="flex flex-col gap-2">
      {monikerRecords.length > 0 &&
        monikerRecords.map((scamEnabler: any, index: number) => {
          return (
            <div className="bg-white p-4 rounded-lg mb-2">
              <p className="text-blue-400 font-bold px-2 py-1">Monikers</p>

              <div className="px-2 py-1 flex flex-row gap-2 items-center">
                <p className="font-bold text-grey-400">Platform : </p>
                <p>{scamEnabler.platform}</p>
              </div>
              <DynamicTable
                tableId={`scamEnablers[Monikers][${index}].rows`}
                formFields={getFormItemsDisplay(getPlatformForIndex(index, 'Monikers'), 'Monikers')}
                isEditable={false}
                formMethods={formMethods}
                data={scamEnabler.rows}
                defaultRow={{}}
                showCounter={false}
                isSimpleView={true}
              />
            </div>
          );
        })}
      {financialInstituteRecords?.length > 0 &&
        financialInstituteRecords.map((financialInstitute: any, index: number) => {
          return (
            <div className="bg-white p-4 rounded-lg mb-2">
              <p className="text-blue-400 font-bold px-2 py-1">Financial Institues</p>
              <DynamicTable
                tableId={`scamEnablers[FinancialInstitutes][${index}].rows`}
                formFields={getFormItemsDisplay(
                  getPlatformForIndex(index, 'FinancialInstitutes'),
                  'FinancialInstitutes',
                )}
                isEditable={false}
                formMethods={formMethods}
                data={financialInstitute.rows}
                defaultRow={{}}
                showCounter={false}
                isSimpleView={true}
              />
            </div>
          );
        })}
      {paynowRecords.length > 0 &&
        paynowRecords.map((paynow: any, index: number) => {
          return (
            <div className="bg-white p-4 rounded-lg mb-2">
              <p className="text-blue-400 font-bold px-2 py-1">Paynow</p>

              <DynamicTable
                tableId={`scamEnablers[Paynow][${index}].rows`}
                formFields={getFormItemsDisplay(getPlatformForIndex(index, 'Paynow'), 'Paynow')}
                isEditable={false}
                formMethods={formMethods}
                data={paynow.rows}
                defaultRow={{}}
                showCounter={false}
                isSimpleView={true}
              />
            </div>
          );
        })}
    </div>
  );
};

// return (
//   <div className="flex flex-col gap-1 h-[45vh] 2xl:h-[52vh] overflow-y-auto overflow-x-hidden">
//     {getScamEnablersComponent()}
//   </div>
// );
// };

export default ScamEnablersDisplay;
