import { FormProvider, useForm } from 'react-hook-form';
import FormHeaderComponent from '../../../components/common/FormHeaderComponent';
import CreateReportComponent from './AdminCreateReportComponent';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { useEffect, useState } from 'react';

const IOFormCreateReportPage = () => {
  const [currentUser, setCurrentUser] = useState<any>({});
  useEffect(() => {
    const getSessionUser = async () => {
      let user;
      user = await getSessionUserFromAccessToken('SatisPlusFormAccessToken');
      setCurrentUser(user);
    };
    getSessionUser();
  }, []);

  const formMethods = useForm({
    values: {
      reportNumber: '',
      scamEnablers: {
        FinancialInstitutes: [{ rows: [{}, {}] }],
        Paynow: [{ rows: [{}, {}] }],
        Monikers: [{}],
        Victims: [{ rows: [{}, {}] }],
        Telcos: [{ rows: [{}, {}] }],
      },
      monetaryLossInvolved: 'false',
      policeStation: '',
      classification: 'Cheating',
      spfEmail: currentUser.email,
    },
    mode: 'onChange',
  });

  return (
    <div className="flex-col h-full items-center justify-center min-h-screen bg-grey-100">
      <div className="flex flex-col w-full h-full gap-2 bg-white xl:my-8 pb-1 rounded-lg">
        <FormHeaderComponent title={'Police Scam Reporting'} />
        <div className="px-8">
          <FormProvider {...formMethods}>
            <CreateReportComponent
              formMethods={formMethods}
              currentUser={currentUser}
              isFormCreateReport={true}
            />
          </FormProvider>{' '}
        </div>
      </div>
    </div>
  );
};

export default IOFormCreateReportPage;
