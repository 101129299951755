import { CommonButton } from '@components/common/CommonButton';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { ReactComponent as ErrorOutline } from '@assets/icons/error_outline.svg';
import FilterModal from '@components/common/FilterModal';
import { useForm } from 'react-hook-form';
import FilterAssets from '@components/common/FilterAssets';
import { getDirectionQueueItems } from '../DirectionQueueFormConfig';
import { GenerateProductionOrderBankQueueButton } from '@pages/portfolios/Buttons';
import { DeleteProductionOrderQueueButton } from '@pages/reports/Buttons';
import { getSessionUserFromAccessToken } from '@utils/utils';
import AccessControl from '@components/common/AccessControl';

const DirectionsQueueHeaderGroup = ({
  selectedRows,
  selectedScamEnablerIds,
  selectedPOQueueIds,
  refetch,
  setTableParams,
  tableParams,
  setSearchTerm,
  view,
}: {
  selectedRows: Array<any>;
  selectedScamEnablerIds: Array<string>;
  selectedPOQueueIds: Array<string>;
  refetch: any;
  tableParams: any;
  setTableParams: any;
  setSearchTerm: any;
  view: string;
}) => {
  const [currentUser, setCurrentUser] = useState<any>();
  const [filterOpen, setFilterOpen] = useState(false);

  const [deleteShowErrorMessage, setDeleteShowErrorMessage] = useState(false);
  const [deleteButtonErrorMessage, setDeleteButtonErrorMessage] = useState('');

  const [showPOErrorMessage, setShowPOErrorMessage] = useState(false);
  const [poButtonErrorMessage, setPoButtonErrorMessage] = useState('');

  useEffect(() => {
    const getUserData = async () => {
      const sessionUser = await getSessionUserFromAccessToken();
      setCurrentUser(sessionUser);
    };

    getUserData();
  }, []);

  const defaultValues = {
    platform: [],
    po_type: '',
    'created_date_from,created_date_to': [],
    created_date_from: '',
    dutyOfficer: [],
  };

  const formMethods = useForm({
    defaultValues: defaultValues,
    mode: 'all',
  });

  const handleSelectedRowsChange = () => {
    if (selectedRows.length > 0) {
      setDeleteButtonErrorMessage('');
      setShowPOErrorMessage(false);
      setPoButtonErrorMessage('');
    } else {
      setDeleteButtonErrorMessage('Please select at least one row to delete.');
      setPoButtonErrorMessage('Please select at least one row to generate PO.');
    }
  };
  useEffect(() => {
    handleSelectedRowsChange();
  }, [selectedRows]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
    });
    setSearchTerm(e.target.value);
  };

  const handleFiltersChange = (filters: any) => {
    if (filters.platform) {
      filters.platform = filters.platform.join(',');
    }
    if (filters.po_type) {
      filters.po_type = filters.po_type.map((e: any) => e.id).join(',');
    }
    if (filters.created_date_from) {
      filters.created_date_from = Date.parse(filters.created_date_from);
    }
    if (filters.created_date_to) {
      filters.created_date_to = Date.parse(filters.created_date_to);
    }
    if (filters.dutyOfficer) {
      filters.duty_officer = filters?.dutyOfficer?.id ? filters.dutyOfficer.id : '';
    }

    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters,
    });
  };

  const isDisableGeneratePOButton = () => {
    let uniqueDutyOfficers: string[] = [];
    for (let row of selectedRows) {
      if (!uniqueDutyOfficers.includes(row.dutyOfficer.userId)) {
        uniqueDutyOfficers.push(row.dutyOfficer.userId);
      }
    }

    if (
      selectedRows.length <= 0 ||
      uniqueDutyOfficers.length !== 1 ||
      uniqueDutyOfficers[0] !== currentUser?.userId
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="h-3 flex justify-end">
        {((showPOErrorMessage && poButtonErrorMessage) ||
          (deleteButtonErrorMessage && deleteShowErrorMessage)) && (
          <div className={`flex flex-row pt-1`}>
            <ErrorOutline className="mr-2" />
            <div className="flex flex-col">
              {showPOErrorMessage && poButtonErrorMessage && (
                <p className="text-grey-400 text-sm italic text-center">{poButtonErrorMessage}</p>
              )}
              {deleteButtonErrorMessage && deleteShowErrorMessage && (
                <p className="text-grey-400 text-sm italic text-center">
                  {deleteButtonErrorMessage}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between gap-2 py-4">
        <div className="flex flex-row gap-4  items-center">
          <div className="w-64">
            <DebounceInput
              className="w-64 h-12 p-4 bg-grey-300 rounded-[8px] border-none text-grey-500 text-sm"
              minLength={2}
              debounceTimeout={300}
              placeholder="Search for account no. or report number..."
              onChange={handleSearch}
            />
          </div>

          <CommonButton
            isDisabled={false}
            variant="secondary"
            title={`Filters`}
            onClick={() => setFilterOpen(true)}
          />
        </div>
        <div className="flex flex-col gap-2 min-h-10">
          <div className="flex flex-row gap-2">
            <DeleteProductionOrderQueueButton
              selectedRows={selectedRows}
              callback={refetch}
              buttonType="text"
              view="bankView"
              setShowErrorMessage={setDeleteShowErrorMessage}
              isDisabled={selectedRows.length <= 0}
            />
            <GenerateProductionOrderBankQueueButton
              financialInstituteData={selectedRows}
              callback={refetch}
              buttonType="text"
              setErrorText={setPoButtonErrorMessage}
              setShowErrorMessage={setShowPOErrorMessage}
              isDisabled={isDisableGeneratePOButton()}
            />
          </div>
        </div>
      </div>
      <FilterAssets
        defaultValues={defaultValues}
        formMethods={formMethods}
        handleFiltersChange={handleFiltersChange}
      />
      <FilterModal
        filterOpen={filterOpen}
        formItems={getDirectionQueueItems(view)}
        formMethods={formMethods}
        onClearFilters={() => formMethods.reset(defaultValues)}
        onFilterClose={() => setFilterOpen(false)}
        onSubmitFilter={(filters: any) => {
          handleFiltersChange(filters);
        }}
      />
    </>
  );
};

export default DirectionsQueueHeaderGroup;
