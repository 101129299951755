export const policeStations = [
  'Ang Mo Kio North Neighbourhood Police Centre',
  'Ang Mo Kio South Neighbourhood Police Centre',
  'Hougang Neighbourhood Police Centre',
  'Punggol Neighbourhood Police Centre',
  'Serangoon Neighbourhood Police Centre',
  'Sengkang Neighbourhood Police Centre',
  'Woodleigh Neighbourhood Police Centre',
  'Bedok Neighbourhood Police Centre',
  'Changi Neighbourhood Police Centre',
  'Geylang Neighbourhood Police Centre',
  'Marine Parade Neighbourhood Police Centre',
  'Pasir Ris Neighbourhood Police Centre',
  'Tampines Neighbourhood Police Centre',
  'Bukit Merah East Neighbourhood Police Centre',
  'Marina Bay Neighbourhood Police Centre',
  'Rochor Neighbourhood Police Centre',
  'Clementi Neighbourhood Police Centre',
  'Bukit Merah West Neighbourhood Police Centre',
  'Jurong East Neighbourhood Police Centre',
  'Queenstown Neighbourhood Police Centre',
  'Nanyang Neighbourhood Police Centre',
  'Bukit Batok Neighbourhood Police Centre',
  'Bukit Panjang Neighbourhood Police Centre',
  'Choa Chu Kang Neighbourhood Police Centre',
  'Jurong West Neighbourhood Police Centre',
  'Kampong Java Neighbourhood Police Centre',
  'Bishan Neighbourhood Police Centre',
  'Bukit Timah Neighbourhood Police Centre',
  'Orchard Neighbourhood Police Centre',
  'Toa Payoh Neighbourhood Police Centre',
  'Sembawang Neighbourhood Police Centre',
  'Woodlands West Neighbourhood Police Centre',
  'Woodlands East Neighbourhood Police Centre',
  'Yishun North Neighbourhood Police Centre',
  'Yishun South Neighbourhood Police Centre',
  'PID',
  'CD-ASC',
  'CADID',
  'X / DIB',
  'CAD / ASCOM',
  'CID / TCIB',
  'J / IB',
  'F / IB',
  'L / IB',
  'G / IB',
  'D / IB',
  'E / IB',
  'A / IB',
  'SDOC',
  'IOCC',
];

export const commonScamType = [
  'E-commerce Scam',
  'Fake Buyer Phishing Scam',
  'Investment Scam',
  'Job Scam',
  'Loan Scam',
  'Love/Parcel Scam',
  'Friend Impersonation Scam',
  'Government Official Impersonation Scam',
  'China Official Impersonation Scam',
  'Chinese Services Impersonation Scam',
  'Phishing Scam',
  'Rental Scam',
  'Offering Sexual Services Scam',
  'Sugar Mummy Scam',
  'Cyber Extortion Scam (Sextortion)',
  'Remittance Scam',
  'Lucky Draw Scam',
  'Blessing Scam',
  'Lottery Scam',
  'Social Media Impersonation Scam',
  'Tech Support Scam',
  'Malware Scam',
  'Business Email Compromised Scam',
  'Fake Gambling Platform Scam',
  'Account Rental',
  'Scam Recovery',
  'Social Media Impersonation involving Political Office Holder',
  'Investment involving Political Office Holder',
  'Other Scam',
  'Feedback on blocked website',
];

export const additionalScamType = [
  'Inheritance Scam',
  'Time Share Scam',
  'Investment (Traditional) Scam',
  'Non Scam',
];

export const scamType = [...commonScamType, ...additionalScamType];

export const platform = [
  'Website',
  'Facebook',
  'Instagram',
  'Telegram',
  'iMessage',
  'RCS',
  // 'On a call',
  'SMS',
  'X',
  'Carousell',
  'Lazada',
  'Shopee',
  'LINE App',
  'Google',
  'Google Playstore Application',
  'WeChat',
  'Whatsapp',
  'Email',
  'TikTok',
  'Others',
];

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua & Deps',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Central African Rep',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo {Democratic Rep}',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland {Republic}',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea North',
  'Korea South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar, {Burma}',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'St Kitts & Nevis',
  'St Lucia',
  'Saint Vincent & the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome & Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad & Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const useOfScamEnabler = [
  'Victim came across',
  'Scammer reach out to victim using this enabler',
  'Others, please state',
];

export const bankPlatforms = [
  'DBS/POSB',
  'CIMB',
  'UOB',
  'SCB',
  'OCBC',
  'HSBC',
  'Trust Bank',
  'ANT',
  'Greenlink',
  'GXS',
  'MariBank',
  'MBB',
  'BOC',
  'CITI',
  'HLB',
  'ICBC',
  'RHB',
  'JP Morgan',
  'SBI',
  'ANZ',
  'Others',
];
export const victimPlatforms = [
  'DBS/POSB',
  'CIMB',
  'UOB',
  'SCB',
  'OCBC',
  'HSBC',
  'Trust Bank',
  'ANT',
  'Greenlink',
  'GXS',
  'MariBank',
  'MBB',
  'BOC',
  'CITI',
  'HLB',
  'ICBC',
  'RHB',
  'JP Morgan',
  'SBI',
  'ANZ',
  'Aspire',
  'Aleta Planet',
  'Alipay',
  'DASH',
  'eRemit / KLIQ',
  'FomoPay',
  'GET',
  'Grab',
  'Liquid Pay',
  'Matchmove',
  'NIUM',
  'PayLah',
  'Payoneer',
  'Payrnet',
  'Quoine',
  'Rapyd',
  'Razer',
  'Revolut',
  'Singpass',
  'SINGX',
  'Western Union',
  'WISE',
  'World First',
  'Others',
];
export const fintechPlatforms = [
  'Aspire',
  'Aleta Planet',
  'Alipay',
  'DASH',
  'eRemit / KLIQ',
  'FomoPay',
  'GET',
  'Grab',
  'Liquid Pay',
  'Matchmove',
  'NIUM',
  'PayLah',
  'Payoneer',
  'Payrnet',
  'Quoine',
  'Rapyd',
  'Razer',
  'Revolut',
  'Singpass',
  'SINGX',
  'Western Union',
  'WISE',
  'World First',
];

export const cryptoPlatforms = [
  'Coinhako',
  'Coinut',
  'Crypto.com',
  'Gemini',
  'Independent Reserve',
  'QCP',
  'Tokenize',
  'Xfers',
];

export const payNowPlatforms = ['Paynow'];
export const financialExchangePlatform = [
  ...payNowPlatforms,
  ...bankPlatforms,
  ...fintechPlatforms,
  ...cryptoPlatforms,
  'Others',
];
// export const financialExchangePlatform = [
//   'Paynow',
//   'DBS',
//   'DBS/POSB',
//   'CIMB',
//   'UOB',
//   'Standard Chartered Bank (SCB)',
//   'OCBC',
//   'HSBC',
//   'Trust Bank',
//   'ANT',
//   'Greenlink',
//   'GXS Bank',
//   'MariBank',
//   'Maybank (MBB)',
//   'Bank of China',
//   'CITI',
//   'Hong Leong Bank (HLB)',
//   'Industrial and Commercial Bank of China (ICBC)',
//   'RHB',
//   'State Bank of India',
//   'ANZ',
//   'Apple ITUNES',
//   'Razer',
//   'WISE',
//   'COINBASE',
//   'COINHAKO',
//   'BINANCE',
//   'REMITTANCE',
//   'OTHERS',
// ];

// TODO: put in actual banks
export const listOfBanks = ['UOB', 'DBS', 'HSBC', 'Maybank'];

// export const purposeOfSubmission = [
//   'Submitting a fresh report',
//   'The previous submission is incorrect',
//   'Adding to the previous submission (new scam enablers found)',
// ];

type paynowStatusMappingType = {
  [key: string]: string;
};

export const paynowStatus = [
  'Pending Review',
  'Under Review',
  'Unlinked',
  'Linked',
  'PO Required',
  'PO Issued',
];

export const paynowStatusMapping: paynowStatusMappingType = {
  pendingReview: 'Pending Review',
  underReview: 'Under Review',
  unlinked: 'Unlinked',
  linked: 'Linked',
  poRequired: 'PO Required',
  poIssued: 'PO Issued',
};

export const bankOutcomesUpdatable = [
  'Frozen',
  'Account Closed',
  'Other Dept Froze',
  'Not Frozen',
  'CENTUM - Not Frozen',
  'Earmarked Funds',
  'No monies to earmark',
  'Invalid Account',
  'Archived',
];

export const bankOutcomes = [
  'Pending Review',
  'Under Review',
  'PO Issued',
  'PO Required',
  ...bankOutcomesUpdatable,
];

export const paynowOutcomesUpdatable = ['Pending BCS', 'No Records Found'];
export const paynowPending = ['Pending BCS'];
export const paynowRecordFounds = ['Records Found'];

export const paynowOutcomes = [...paynowOutcomesUpdatable];

type reportStatusMappingType = {
  [key: string]: string;
};

export const reportStatus = ['Pending Review', 'In Progress', 'Review Completed'];

export const reportStatusMapping: reportStatusMappingType = {
  pendingReview: 'Pending Review',
  underReview: 'Under Review',
  pendingDecision: 'Pending Decision',
  inProgress: 'In Progress',
  pendingStakeholders: 'Pending Stakeholders Response',
  caseClosed: 'Case Closed',
};

export const scamEnablerOutcomes = [
  'Pending Review',
  'Under Review',
  'Pending Decision',
  'PO Required',
  'PO Issued',
  'Terminated',
  'Not Terminated',
  'No Action Required',
  'Archived',
];

export const scamEnablerOutcomesUpdate = [
  'Pending Review',
  'Under Review',
  'Pending Decision',
  'PO Issued',
  'Terminated',
  'Not Terminated',
  'No Action Required',
  'Archived',
];

export const proactiveScamEnablerOutcomes = [
  'Under Review',
  'Submitted',
  'Restrict SG',
  'Terminated',
  'Not Terminated',
  'RFI',
  'No Action Required',
];

export const proactiveProfileType = ['Page', 'User'];
export const monikerOutcomeStatusMapping: reportStatusMappingType = {
  'PO Required': 'PO Required',
  'PO Issued': 'PO Issued',
  Terminated: 'Terminated',
  'Not Terminated': 'Not Terminated',
  'No Action Required': 'No Action Required',
  'Pending Review': 'Pending Review',
  'Under Review': 'Under Review',
  'Pending Decision': 'Pending Decision',
  Archived: 'Archived',
  Cancelled: 'Cancelled',
};

export const proactiveMonikerOutcomeStatusMapping: reportStatusMappingType = {
  'Under Review': 'Under Review',
  Submitted: 'Submitted',
  'Restrict SG': 'Restrict SG',
  Terminated: 'Terminated',
  'Not Terminated': 'Not Terminated',
  RFI: 'RFI',
  'No Action Required': 'No Action Required',
};

export const checkboxItemsInternalWorkProcesses = [
  {
    id: 'internalWorkProcessesQ1',
    name: 'Does the screenshot match the enabler(s) (i.e. user ID / number) submitted? If yes, continue to fill up Part A - pre-cleared criteria for OCHA channel',
    tableFieldType: 'checkbox',
  },
  {
    id: 'internalWorkProcessesQ2',
    name: 'Is the page still live?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'internalWorkProcessesQ3',
    name: 'What is the social media account opening date?',
    tableFieldType: 'date',
  },
  {
    id: 'internalWorkProcessesQ4',
    name: 'Any change in the online name / moniker of the social media account?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'internalWorkProcessesQ5',
    name: 'Is the social media account / number traced to other reports?',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsOnlineMaterial = [
  {
    id: 'sectionA1OchaDa1',
    name: 'You reasonably suspect that a scam has been committed and that any online activity is in furtherance of the commission of the offence under Section 6(1)(a) of Online Criminal Harms Act 2023.',
    tableFieldType: 'checkbox',
  },
  {
    id: 'sectionA2OchaDa1',
    name: 'You suspect or has reason to believe that any online activity is preparatory to, or in furtherance of, the commission of a scam under Section 6(1)(b) of Online Criminal Harms Act 2023.',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsScamChecklist = [
  {
    id: 'sectionB1OchaDa1',
    name: 'Reasonable suspicion that the offence has a nexus with Singapore ie. Occurred in Singapore, Targeting victims in Singapore etc',
    tableFieldType: 'checkbox',
  },
  {
    id: 'sectionB2OchaDa1',
    name: 'Any other parameter(s) which may further define scams as decided by ASC.',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsEvaluation = [
  {
    id: 'sectionC1OchaDa1',
    name: 'Is this a fresh submission? (There is no duplication)',
    tableFieldType: 'checkbox',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    id: 'sectionC2OchaDa1',
    name: 'Is the online material a Mobile Application?',
    tableFieldType: 'checkbox',
  },
  {
    id: 'sectionC3OchaDa1',
    name: 'Application does not belong to any legitimate entity.',
    dependency: 'sectionC2OchaDa1',
    dependencyLabel: 'Only applicable if Q2 is a Yes',
    tableFieldType: 'checkbox',
  },
  {
    id: 'sectionC4OchaDa1',
    name: 'Removal of application will not affect legitimate entities.',
    dependency: 'sectionC2OchaDa1',
    dependencyLabel: 'Only applicable if Q2 is a Yes',
    tableFieldType: 'checkbox',
  },
  {
    id: 'sectionC5OchaDa1',
    name: 'What is the type of direction to issue?',
    options: ['Stop Communication Direction', 'Option 2', 'Option 3'],
    placeholder: 'Please select',
    tableFieldType: 'dropdown',
    rules: {
      required: 'Please select an option',
    },
  },
];

export const checkboxItemsLegalRequirements = [
  {
    id: 'sectionA1OchaDa2',
    name: 'Direction request meets the requirements under Sec 6(1)(a) or Sec 6(1)(b) OCHA?',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsOperationalRequirements = [
  {
    id: 'sectionB1OchaDa2',
    name: 'Is the online material / location* populated correctly? * “Online location” means any internet domain, website, webpage, chatroom, channel, group, forum or any other location, that can be accessed by means of the internet. ',
    tableFieldType: 'checkbox',
  },
  {
    id: 'sectionB2OchaDa2',
    name: 'Is the identified Direction appropriate to block the online material/ account?',
    tableFieldType: 'checkbox',
  },
];

export const checkboxItemsChecks = [
  {
    id: 'sectionB3OchaDA2',
    name: 'Is this a fresh submission? (There is no duplication)',
    tableFieldType: 'checkbox',
  },
];
export const offenceList = [
  'Unauthorised access to computer material, Section 3(1) of Computer Misuse Act 1993',
  'Unauthorised modification of computer material, Section S. 5(1)(a) of Computer Misuse Act 1993',
  'Disclosure of password, access code, etc., in relation to national digital identity service, Section 8A(1) of Computer Misuse Act 1993',
  'Supplying, etc., credential of another person, Section 8B(1) of Computer Misuse Act 1993',
  'Extortion, Section 384 of Penal Code 1871',
  'Cheating and dishonestly inducing a delivery of property, Section 420 of Penal Code 1871',
  'Obtaining services dishonestly or fraudulently, Section 420A of Penal Code 1871',
  'Breach of Agreement',
  'No Offence Disclosed',
  'Others',
];

export const ochaDirections = [
  'Stop Communication Direction',
  'Disabling Direction',
  'Access Blocking Direction',
  'Account Restriction Direction',
  'App Removal Direction',
];

export type Transaction = {
  amountLoss: number;
  currency: string;
  amountLossInSGD: number;
  dateOfTransfer: string;
};

export const classifications = [
  'Cheating',
  'CDSA',
  'CMA',
  'PSA',
  'BOA',
  'Extortion',
  'PAR',
  'Others',
];

export const accountTypes = ['Personal', 'Corporate'];

export const transactionTypes = ['L-L', 'L-O', 'O-L', 'O-O'];

export const divisions = [
  'A',
  'APD',
  'ASCom',
  'ASD',
  'CAD',
  'CAD/ACB',
  'CAD/FIB',
  'CID',
  'CID/SCB',
  'D',
  'DO CAD',
  'E',
  'F',
  'FIB',
  'G',
  'IPRB',
  'J',
  'L',
  'P',
  'TCIB',
  'UMSF',
];

export const currencies = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'FOK',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KID',
  'KMF',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STN',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TVD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XDR',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
];

export const scamTypesBriefFactsMapping = {
  'Account Rental':
    'The enabler submitted was used to procure Singpass accounts, bank accounts, and accounts from various financial institutions from users. The accounts were subsequently used in scams and other illicit activities to launder money. The sales or rental of such accounts is prohibited and in violation of the laws in Singapore.',
  'Blessing Scam':
    "The enabler submitted was used to perpetuate scams. Upon transferring admin payment, culprit did not transfer the victims' winnings as promised.",
  'Business Email Compromised Scam':
    "The enabler submitted was used in spear Phishing of targeted company, disguising as supplier and provided an 'updated' bank account for the transfer of payment for purchasing of supplies and raw materials.",
  'China Official Impersonation Scam':
    'The enabler submitted was used to perpetuate scams, by scammers impersonating as China Officials threatening victims into paying monies for myriad false fines and fees.',
  'Chinese Services Impersonation Scam':
    "The enabler submitted was used to perpetuate scams by scammers impersonating Chinese companies, deceiving victims into making various false payments or harvesting their banking credentials to facilitate unauthorised transfers from victims' accounts.",
  'Cyber Extortion Scam (Sextortion)':
    "The enabler submitted was used to threaten victims being asked for ransom of victim's recorded sex videos.",
  'E-commerce Scam':
    'The enabler submitted was used to perpetuate scams, with victims being asked for repeated online payments for products that they never received.',
  'Fake Buyer Phishing Scam':
    'The enabler submitted was used to perpetuate scams, with victim being asked to key their internet banking credentials on phishing pages for the sales of their item.',
  'Fake Gambling Platform Scam':
    'The enabler submitted was used to perpetuate scams, with victims being asked for placement of gambling bets via fund transfer on gambling platform that do not exist.',
  'Feedback on blocked website':
    'The enabler was surfaced through a feedback from members of public.',
  'Friend Impersonation Scam':
    'The enabler submitted was used to perpetuate scams, by scammers impersonating as friends for emergency loan.',
  'Government Official Impersonation Scam':
    'The enabler submitted was used to perpetuate scams, by scammers impersonating as Government Officials threatening victims into paying monies for myriad false fines and fees.',
  'Inheritance Scam':
    "The enabler submitted was used to perpetuate scams, by scammers impersonating as law firms representing a deceased acquaintance or distant family member in return for a deposit for processing of inheritance under the name of the victim in accordance to the 'will' of the deceased.",
  'Investment (Traditional) Scam':
    'The enabler submitted was used to dishonestly deceive victims into participating in traditional investment schemes, causing the victim to suffer financial losses.',
  'Investment involving Political Office Holder':
    'The enabler submitted was used to perpetuate scams, with victims being asked for repeated payments for investments that never paid out. The enabler submitted is running investment scam advertisement using image of Political Office Holder [INSERTNAME].',
  'Investment Scam':
    'The enabler submitted was used to perpetuate scams, with victims being asked for repeated payments for investments on fake investment platforms.',
  'Job Scam':
    'The enabler submitted was used to perpetuate scams, with victims being asked for repeated payments to be employed as part-time online store operator.',
  'Loan Scam':
    'The enabler submitted was used to advertise and perpetuate scams, with victims being asked for repeated payments for loan offers that do not pay out.',
  'Lottery Scam':
    'The enabler submitted was used to perpetuate scams, with victims being asked for payments of sure win lotteries that do not exist.',
  'Love/Parcel Scam':
    'The enabler submitted was used to perpetuate scams, by grooming victims into a romantic relationship then asking them to transfer monies into specific bank accounts or telegraphic transfers for shipment and clearance fee of parcel.',
  'Lucky Draw Scam':
    'The enabler submitted was used to perpetuate scams, with victims being asked for repeated payments to receive lucky draw winnings that do not exist.',
  'Malware Scam':
    'The enabler submitted was used to perpetuate scams, by scammers impersonating services company and inducing victims to download malicious apps.',
  'Non Scam':
    'The enabler submitted was used to facilitate illegal activities or violations of the law.',
  'Offering Sexual Services Scam':
    'The enabler submitted was used to perpetuate scams, with victims being asked for repeated payments for offering of sexual services that they never received.',
  'Other Scam': 'The enabler submitted was used to perpetrate scam.',
  'Phishing Scam':
    "The enabler submitted led to credential harvesting and unauthorised fund transfer from victim's bank account.",
  'Remittance Scam':
    'The enabler submitted was used to lure victim into transferring money to bank account for the purpose of remittance. However, after transferring the monies, victim did not receive the requested overseas currency.',
  'Rental Scam':
    'The enabler submitted was used to advertise and perpetuate scams, with victims being asked for repeated rental downpayments for bogus rent offers.',
  'Scam Recovery':
    'The enabler submitted was used to perpetuate scams by posing as a legitimate service offering to help scam victims recover their lost funds. Victims were deceived into paying upfront fees for purported recovery services that were never actually provided.',
  'Social Media Impersonation involving Political Office Holder':
    'The enabler submitted was used to perpetuate scams by scammers impersonating a Political Office Holder [INSERTNAME] and using their image to deceive victims into making payments for various fraudulent purposes.',
  'Social Media Impersonation Scam':
    'The enabler submitted was used to perpetuate scams, by scammers impersonating as acquaintance to trick victims into paying monies for myriad false fines, fees, or lucky draw opportunities.',
  'Sugar Mummy Scam':
    'The enabler submitted was used to lure victim into paying deposit and membership to register as member on Sugar Mummy websites.',
  'Tech Support Scam':
    'The enabler submitted was used to perpetuate scams, by scammers impersonating as Technical Support Staff to trick victims into paying money- to proof their innocent in supposed investigation into false money laundering activities.',
  'Time Share Scam':
    'The enabler submitted was used to dishonestly deceive victims into participating in a timeshare scam, causing the victim to suffer financial losses.',
};
export const designations = [
  'Head, Anti-Scam Centre',
  'Officer-in-Charge, Anti-Scam Centre',
  'Senior Investigation Officer, Anti-Scam Centre',
  'Head Assessment, Decision Authority Office',
  'Officer-in-Charge Assessment, Decision Authority Office',
  'Assessment Officer, Decision Authority Office',
  'Assessment Officer, PID',
];
