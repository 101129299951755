import { CommonButton } from '@components/common/CommonButton';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { ReactComponent as ErrorOutline } from '@assets/icons/error_outline.svg';
import { monikerOutcomeStatusMapping } from '@interface/constant';
import FilterModal from '@components/common/FilterModal';
import { useForm } from 'react-hook-form';
import FilterAssets from '@components/common/FilterAssets';
import { getDirectionQueueItems } from '../DirectionQueueFormConfig';
import { GenerateProductionOrderButton } from '@pages/portfolios/Buttons';
import { DeleteProductionOrderQueueButton } from '@pages/reports/Buttons';

// const dropdownData = [
//   {
//     id: 'monikers',
//     label: 'Monikers',
//   },
//   {
//     id: 'reports',
//     label: 'Reports',
//   },
// ];

const DirectionsQueueHeaderGroup = ({
  selectedRows,
  selectedScamEnablerIds,
  selectedPOQueueIds,
  refetch,
  setTableParams,
  tableParams,
  setSearchTerm,
  view,
}: {
  selectedRows: Array<any>;
  selectedScamEnablerIds: Array<string>;
  selectedPOQueueIds: Array<string>;
  refetch: any;
  tableParams: any;
  setTableParams: any;
  setSearchTerm: any;
  view: string;
}) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const [deleteShowErrorMessage, setDeleteShowErrorMessage] = useState(false);
  const [deleteButtonErrorMessage, setDeleteButtonErrorMessage] = useState('');

  const [showPOErrorMessage, setShowPOErrorMessage] = useState(false);
  const [poButtonErrorMessage, setPoButtonErrorMessage] = useState('');

  const defaultValues = {
    outcome: [],
    scam_type: [],
    'created_date_from,created_date_to': [],
    created_date_from: '',
    created_date_to: '',
    channel: '',
    direction: '',
  };

  const formMethods = useForm({
    defaultValues: defaultValues,
    mode: 'all',
  });

  const handleSelectedRowsChange = () => {
    if (selectedRows.length > 0) {
      setDeleteButtonErrorMessage('');
    } else {
      setDeleteButtonErrorMessage('Please select at least one row to delete.');
      setPoButtonErrorMessage('Please select at least one row to generate PO.');
    }
  };
  useEffect(() => {
    handleSelectedRowsChange();
  }, [selectedRows]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
    });
    setSearchTerm(e.target.value);
  };

  const handleFiltersChange = (filters: any) => {
    if (filters.outcome) {
      let newOutcomeStatusFilters: Array<string> = [];
      filters.outcome.forEach((outcome: string) => {
        let statusCode = Object.keys(monikerOutcomeStatusMapping).find(
          (key) => monikerOutcomeStatusMapping[key] === outcome,
        );

        if (statusCode) newOutcomeStatusFilters.push(statusCode);
      });

      filters.outcome = newOutcomeStatusFilters.join(',');
    }
    if (filters.scam_type) {
      filters.scam_type = filters.scam_type.join(',');
    }
    if (filters.created_date_from) {
      filters.created_date_from = Date.parse(filters.created_date_from);
    }
    if (filters.created_date_to) {
      filters.created_date_to = Date.parse(filters.created_date_to);
    }
    if (filters.platform) {
      filters.platform = filters.platform.join(',');
    }
    if (filters.direction) {
      filters.direction = filters.direction.join(',');
    }
    if (filters.channel) {
      filters.channel = filters.channel.join(',');
    }

    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters,
    });
  };

  return (
    <>
      <div className="h-3 flex justify-end">
        {((showPOErrorMessage && poButtonErrorMessage) ||
          (deleteButtonErrorMessage && deleteShowErrorMessage)) && (
          <div className={`flex flex-row pt-1`}>
            <ErrorOutline className="mr-2" />
            <div className="flex flex-col">
              {showPOErrorMessage && poButtonErrorMessage && (
                <p className="text-grey-400 text-sm italic text-center">{poButtonErrorMessage}</p>
              )}
              {deleteButtonErrorMessage && deleteShowErrorMessage && (
                <p className="text-grey-400 text-sm italic text-center">
                  {deleteButtonErrorMessage}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between gap-2 py-4">
        <div className="flex flex-row gap-4  items-center">
          <div className="w-64">
            <DebounceInput
              className="w-64 h-12 p-4 bg-grey-300 rounded-[8px] border-none text-grey-500 text-sm"
              minLength={2}
              debounceTimeout={300}
              placeholder="Search for report no. or moniker ID..."
              onChange={handleSearch}
            />
          </div>

          <CommonButton
            isDisabled={false}
            variant="secondary"
            title={`Filters`}
            onClick={() => setFilterOpen(true)}
          />
        </div>
        <div className="flex flex-col gap-2 min-h-10">
          <div className="flex flex-row gap-2">
            <DeleteProductionOrderQueueButton
              selectedRows={selectedRows}
              callback={refetch}
              buttonType="text"
              view="monikerView"
              setShowErrorMessage={setDeleteShowErrorMessage}
              isDisabled={selectedRows.length <= 0}
            />
            <GenerateProductionOrderButton
              scamEnablerData={selectedRows}
              callback={refetch}
              type="text"
              setErrorText={setPoButtonErrorMessage}
              setShowErrorMessage={setShowPOErrorMessage}
              sourcePage="directionsQueue"
            />
          </div>
        </div>
      </div>
      <FilterAssets
        defaultValues={defaultValues}
        formMethods={formMethods}
        handleFiltersChange={handleFiltersChange}
      />
      <FilterModal
        filterOpen={filterOpen}
        formItems={getDirectionQueueItems(view)}
        formMethods={formMethods}
        onClearFilters={() => formMethods.reset(defaultValues)}
        onFilterClose={() => setFilterOpen(false)}
        onSubmitFilter={(filters: any) => {
          handleFiltersChange(filters);
        }}
      />
    </>
  );
};

export default DirectionsQueueHeaderGroup;
