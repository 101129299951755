import { cloneDeep } from 'lodash';
import {
  filterEmptyRows,
  validateFormValues,
  convertYYYYMMDDToUnixTimestamp,
  generateReportNumber,
  cleanUpUserId,
} from '@utils/utils';
import { getFormItems } from '@pages/reports/CreateReportPage/AdminCreateReportFormConfig';
import { showAlert } from '@store/alertSlice';
const satisPoliceStations = ['PID', 'CADID', 'X / DIB'];

export const formatScamEnablers = (scamEnablers: any) => {
  scamEnablers.forEach((scamEnabler: any) => {
    delete scamEnabler['userIdType'];
    if (scamEnabler.platform === 'Website') {
      scamEnabler.websites.forEach((websiteScamEnabler: any) => {
        scamEnabler['attachments'] = [];
        if (!websiteScamEnabler['attachments']) {
          websiteScamEnabler['attachments'] = [];
        } else {
          websiteScamEnabler['attachments'].forEach((attachment: any) => {
            attachment.fileContent = attachment.fileContent.replace(/^data:.+;base64,/, '');
          });
        }
      });
    } else {
      if (!scamEnabler['attachments'] || scamEnabler['attachments'] === '-') {
        scamEnabler['attachments'] = [];
      } else {
        scamEnabler['attachments'].forEach((attachment: any) => {
          attachment.fileContent = attachment.fileContent.replace(/^data:.+;base64,/, '');
        });
      }
      if (scamEnabler['userId']) {
        scamEnabler['userId'] = cleanUpUserId(scamEnabler['userId'], scamEnabler['platform']);
      }
      if (scamEnabler['transactions']) {
        let transactions: any = [];
        if (scamEnabler.transactions && Array.isArray(scamEnabler.transactions)) {
          scamEnabler.transactions.forEach((transaction: any) => {
            if (
              (!transaction.dateOfTransfer &&
                !transaction.amountLoss &&
                transaction.currency === 'SGD' &&
                transaction.transactionType === 'L-L' &&
                !transaction.victimPlatform &&
                !transaction.victimAccountNumber) ||
              (!transaction.dateOfTransfer &&
                !transaction.amountLoss &&
                !transaction.currency &&
                !transaction.transactionType &&
                !transaction.victimPlatform &&
                !transaction.victimAccountNumber)
            ) {
            } else {
              let newTransaction: any = cloneDeep(transaction);
              if (transaction.dateOfTransfer) {
                newTransaction.dateOfTransfer = convertYYYYMMDDToUnixTimestamp(
                  transaction.dateOfTransfer,
                );
              }
              if (!transaction.currency) {
                newTransaction.currency = 'SGD';
              }
              if (!transaction.transactionType) {
                newTransaction.currency = 'L-L';
              }
              if (!transaction.amountLoss) {
                newTransaction.amountLoss = 0;
              }
              if (!transaction.dateOfTransfer) {
                newTransaction.dateOfTransfer = '';
              }
              if (!transaction.victimPlatform) {
                newTransaction.victimPlatform = '';
              }
              if (!transaction.victimAccountNumber) {
                newTransaction.victimAccountNumber = '';
              }

              transactions.push(transaction);
            }
          });
        }

        scamEnabler.transactions = transactions;
      }
      delete scamEnabler.scamEnablerType;
    }
  });
  return scamEnablers;
};

export const getScamEnablers = (formMethods: any) => {
  const formValues: any = formMethods.getValues();

  let financialInstituteRecords = filterEmptyRows(formValues?.scamEnablers?.FinancialInstitutes);
  let paynowRecords = filterEmptyRows(formValues?.scamEnablers?.Paynow);
  let monikerRecords = filterEmptyRows(formValues?.scamEnablers?.Monikers);
  let telcoRecords = filterEmptyRows(formValues?.scamEnablers?.Telcos);
  let victimRecords = filterEmptyRows(formValues?.scamEnablers?.Victims);

  if (
    !(
      financialInstituteRecords?.length > 0 ||
      paynowRecords?.length > 0 ||
      monikerRecords?.length > 0 ||
      telcoRecords?.length > 0 ||
      victimRecords?.length > 0
    )
  ) {
    return null;
  }
  return {
    financialInstituteRecords,
    paynowRecords,
    monikerRecords,
    telcoRecords,
    victimRecords,
  };
};

export const validateScamEnablers = (
  formValues: any,
  formMethods: any,
  isTriggerFieldValidation: boolean,
) => {
  const triggerRowValidation = (
    row: any,
    categoryIndex: number,
    rowIndex: number,
    category: string,
    platform?: string,
  ) => {
    const formItems = getFormItems(platform || '', category);
    const fieldPaths = formItems.map(
      (field: any) => `scamEnablers.${category}[${categoryIndex}].rows[${rowIndex}].${field.id}`,
    );

    // Trigger validation for all fields in the row
    if (isTriggerFieldValidation) formMethods.trigger(fieldPaths);

    return validateFormValues(row, formItems);
  };

  let errors: any = {
    financialInstitutes: [],
    paynow: [],
    monikers: [],
    telcos: [],
    scamEnablers: [],
  };

  let scamEnablers = getScamEnablers(formMethods);
  if (!scamEnablers) {
    errors.scamEnablers = ['No ScamEnablers'];
    return errors;
  }

  if (scamEnablers?.financialInstituteRecords[0]?.rows?.length > 0) {
    scamEnablers?.financialInstituteRecords[0].rows.forEach((row: any, index: number) => {
      errors.financialInstitutes = errors.financialInstitutes.concat(
        triggerRowValidation(row, 0, index, 'FinancialInstitutes'),
      );
    });
  }

  // Validate Paynow
  if (scamEnablers?.paynowRecords[0]?.rows?.length > 0) {
    scamEnablers?.paynowRecords[0].rows.forEach((row: any, index: number) => {
      errors.paynow = errors.paynow.concat(triggerRowValidation(row, 0, index, 'Paynow'));
    });
  }

  // Validate Telcos
  if (scamEnablers?.telcoRecords[0]?.rows?.length > 0) {
    scamEnablers?.telcoRecords[0].rows.forEach((row: any, index: number) => {
      errors.telcos = errors.telcos.concat(triggerRowValidation(row, 0, index, 'Telcos'));
    });
  }

  // Validate Monikers
  if (scamEnablers?.monikerRecords?.length > 0) {
    scamEnablers?.monikerRecords.forEach((platformRow: any, platformIndex: number) => {
      if (platformRow.platform && platformRow?.rows?.length > 0) {
        platformRow.rows.forEach((row: any, index: number) => {
          errors.monikers = errors.monikers.concat(
            triggerRowValidation(row, platformIndex, index, 'Monikers', platformRow.platform),
          );
        });
      }
    });
  }
  return errors;
};

export const handleCreateReportButton = async (
  formMethods: any,
  currentUser: any,
  dispatch: any,
  callback: any,
  createReport: any,
  setSubmissionId: any,
  setLoading: any,
) => {
  try {
    setLoading(true);

    const formValues: any = formMethods.getValues();

    let params = {
      report: {
        reportNumber:
          formValues.reportNumber.length > 0
            ? formValues.reportNumber
            : generateReportNumber('PID'),
        spfEmail: formValues.spfEmail,
        policeStation: formValues.policeStation,
        classification: formValues.classification,
        scamType: formValues.scamType,
        scamSubType: formValues.scamSubType,
      },
      scamEnablers: {},
    };
    let scamEnablers: any = [];
    let formScamEnablers = getScamEnablers(formMethods);
    if (!formScamEnablers) {
      return;
    }
    formScamEnablers.monikerRecords.forEach((group: any) => {
      if (group?.rows?.length > 0) {
        let tempScamEnabler = {};
        group.rows.forEach((scamEnabler: any) => {
          let platform = group.platform;
          if (platform === 'iMessage') {
            if (scamEnabler?.userIdType === 'Phone Number') {
              scamEnabler.userId = scamEnabler['userId-phone'];
              delete scamEnabler['userId-phone'];
            } else if (scamEnabler?.userIdType === 'Email') {
              scamEnabler.userId = scamEnabler['userId-email'];
              delete scamEnabler['userId-email'];
            }
          }
          tempScamEnabler = {
            ...scamEnabler,
            platform: platform,
          };
          scamEnablers.push(tempScamEnabler);
        });
      }
    });
    formScamEnablers.paynowRecords.forEach((group: any) => {
      let tempScamEnabler = {};
      group.rows.forEach((scamEnabler: any) => {
        tempScamEnabler = {
          ...scamEnabler,
          platform: 'Paynow',
          type: 'Payment',
          transactions: scamEnabler?.transactions?.length > 0 ? scamEnabler.transactions : [],
        };
        scamEnablers.push(tempScamEnabler);
      });
    });
    formScamEnablers.financialInstituteRecords.forEach((group: any) => {
      let tempScamEnabler = {};
      group.rows.forEach((scamEnabler: any) => {
        tempScamEnabler = {
          ...scamEnabler,
          type: 'Payment',
          transactions: scamEnabler?.transactions?.length > 0 ? scamEnabler.transactions : [],
        };
        scamEnablers.push(tempScamEnabler);
      });
    });
    formScamEnablers.telcoRecords.forEach((group: any) => {
      let tempScamEnabler = {};
      group.rows.forEach((scamEnabler: any) => {
        tempScamEnabler = {
          ...scamEnabler,
          type: 'Telco',
        };
        scamEnablers.push(tempScamEnabler);
      });
    });
    formScamEnablers.victimRecords.forEach((group: any) => {
      let tempScamEnabler = {};
      group.rows.forEach((scamEnabler: any) => {
        tempScamEnabler = {
          ...scamEnabler,
          type: 'Victim',
          transactions: scamEnabler?.transactions?.length > 0 ? scamEnabler.transactions : [],
        };
        scamEnablers.push(tempScamEnabler);
      });
    });
    params.scamEnablers = formatScamEnablers(scamEnablers);
    if (satisPoliceStations.includes(params.report.policeStation)) {
      delete params.report.reportNumber;
    }
    let data: any;
    // check if form create report or portal create report
    data = await createReport(params);

    setLoading(false);

    if (!data.error) {
      setSubmissionId(data.data.submissionId);
      dispatch(
        showAlert({
          type: 'success',
          message: `New report added successfully.`,
        }),
      );
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    console.log('Error creating report', e);
    setLoading(false);
    dispatch(
      showAlert({
        type: 'error',
        message: `Error creating report. Please try again.`,
      }),
    );
  }
};
