import Breadcrumbs from '@components/common/Breadcrumbs';
import AdminCreateReportComponent from './AdminCreateReportComponent';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { getSessionDataUser } from '@utils/utils';
import AccessControl from '@components/common/AccessControl';

const AdminCreateReportPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reportType = queryParams.get('route');

  const breadcrumbsLabel = reportType === 'reports' ? 'Reports' : 'Monikers(Reported)';
  // const [currentUser, setCurrentUser] = useState<any>({});

  // useEffect(() => {
  //   const getSessionUser = async () => {
  //     let user;

  //     user = await getSessionUserFromAccessToken();

  //     setCurrentUser(user);
  //   };
  //   getSessionUser();
  //   console.log(currentUser);
  // }, []);
  const currentUser = getSessionDataUser();

  const breadcrumbsLink =
    reportType === 'reports' ? '/admin/reports' : '/admin/portfolios/monikers';
  const formMethods = useForm({
    values: {
      reportNumber: '',
      scamEnablers: {
        FinancialInstitutes: [{ rows: [{}, {}] }],
        Paynow: [{ rows: [{}, {}] }],
        Monikers: [{}],
        Victims: [{ rows: [{}, {}] }],
        Telcos: [{ rows: [{}, {}] }],
      },
      monetaryLossInvolved: 'false',
      policeStation: 'CAD / ASCOM',
      classification: 'Cheating',
      spfEmail: currentUser.email,
    },
    mode: 'onChange',
  });

  return (
    <>
      <AccessControl
        type="page"
        allowedPermissions={[
          'reports.scamenablers.create',
          'reports.banks.create',
          'reports.paynows.create',
        ]}
      >
        <div className="w-100 bg-grey-200 p-4 flex flex-row justify-between items-center">
          <Breadcrumbs
            breadcrumbs={[
              { label: breadcrumbsLabel, href: breadcrumbsLink },
              {
                label: `Add Report - Manual Entry`,
                href: `/admin/reports/create`,
                active: true,
              },
            ]}
          />
        </div>

        <div className="flex flex-col h-full items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col w-full h-full gap-2 bg-white  rounded-lg ">
            <FormProvider {...formMethods}>
              <AdminCreateReportComponent formMethods={formMethods} currentUser={currentUser} />
            </FormProvider>
          </div>
        </div>
      </AccessControl>
    </>
  );
};

export default AdminCreateReportPage;
