import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';

import { Modal, Badge } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { Table } from 'antd';
import { facebookColumns } from './monikerColumns/facebookColumns';
import { shopeeColumns } from './monikerColumns/shopeeColumns';
import { lazadaColumns } from './monikerColumns/lazadaColumns';
import { carousellColumns } from './monikerColumns/carousellColumns';
import { CommonButton } from '@components/common/CommonButton';
import { whatsappColumns } from './monikerColumns/whatsappColumns';
import { iMessageColumns } from './monikerColumns/iMessageColumns';

interface ProductionOrderGenerateModalProps {
  platform: string;
  data: any;
  openModal: boolean;
  channel: string;
  handleCloseModal: () => void;
  handleClick: () => void;
  isLoading: boolean;
}

const ProductionOrderGenerateModal: React.FC<ProductionOrderGenerateModalProps> = ({
  platform,
  data,
  openModal,
  channel,
  handleCloseModal,
  handleClick,
  isLoading,
}) => {
  const [platformColumns, setPlatformColumns] = useState<any>([]);
  useEffect(() => {
    switch (platform) {
      case 'Facebook':
      case 'Instagram':
        setPlatformColumns(cloneDeep(facebookColumns()));
        break;
      case 'Shopee':
        setPlatformColumns(cloneDeep(shopeeColumns));
        break;
      case 'Carousell':
        setPlatformColumns(cloneDeep(carousellColumns));
        break;
      case 'Whatsapp':
        setPlatformColumns(cloneDeep(whatsappColumns));
        break;
      case 'TikTok':
      case 'Telegram':
      case 'X':
      case 'Lazada':
        setPlatformColumns(cloneDeep(lazadaColumns));
        break;
      case 'RCS':
      case 'iMessage':
        setPlatformColumns(cloneDeep(iMessageColumns));
        break;
      default:
        setPlatformColumns([]);
    }
  }, [platform]);

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <ModalLayout
          title={
            <div className="flex items-center gap-3">
              <span>{`Generate PO (${platform})`}</span>
              {channel && (
                <div className="ml-2">
                  <span 
                    style={{
                      display: 'inline-block',
                      backgroundColor: channel === "Community Guideline" ? '#e6f2eb' : '#fbe7ed',
                      color: channel === "Community Guideline" ? '#0c6b3d' : '#d63965',
                      border: channel === "Community Guideline" ? '1px solid #c1e4d0' : '1px solid #f8c8d5',
                      padding: '1px 10px',
                      fontSize: '0.875rem',
                      borderRadius: '6px'
                    }}
                  >
                    {channel === "Community Guideline" ? "CGC" : "OCHA"}
                  </span>
                </div>
              )}
            </div>
          }
          handleCloseModal={handleCloseModal}
          alignment="center"
          size="medium"
        >
          {platform === 'Facebook' ||
            (platform === 'Carousell' && (
              <p className="pb-4 text-sm">
                The following information will be download in an excel document after clicking on
                the ‘Generate’ button.
              </p>
            ))}
          {platform === 'Shopee' && (
            <p className="pb-4 text-sm">
              The following information will be sent to your email to be forwarded to Shopee for
              take down of the listed accounts
            </p>
          )}
          {platform === 'Lazada' && (
            <p className="pb-4 text-sm">
              The following information will be download in a PDF document after clicking on the
              the ‘Generate’ button.
              </p>
          )}
          <Table
            rowKey="_id"
            className="mt-8"
            dataSource={data}
            columns={platformColumns}
            pagination={false}
          />
          <div className="flex justify-end pt-2">
            <button className="button px-4 rounded-lg font-bold" onClick={handleCloseModal}>
              Cancel
            </button>
            <CommonButton
              isDisabled={isLoading}
              variant="primary"
              title={`Generate Production Order`}
              onClick={handleClick}
              isLoading={isLoading}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default ProductionOrderGenerateModal;
